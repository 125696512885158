import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  Section,
  H2,
  H3,
  P,
  Container,
  NavButton,
  Icon,
  SendIcon,
  Img,
} from '../../components'

import image1 from '../../../../../public/images/innovations/exhaustiviteInnovations.svg'
import image2 from '../../../../../public/images/innovations/adaptabiliteInnovations.svg'
import image3 from '../../../../../public/images/innovations/efficaciteInnovations.svg'

const StyledSection = styled(Section)`
  padding: 1rem 0;
  text-align: center;
  margin: 3rem 4rem;

  @media (max-width: 1024px) {
    margin: 1rem 2rem;
  }
`

const Title = styled(H2)`
  font-family: Blinker;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 2rem;
  font-size: 2.3rem;

  @media (min-width: 960px) {
    letter-spacing: 0.2em;
  }
`

const StyledH3 = styled(H3)`
  margin: auto;
  width: 80%;
  margin-bottom: 2rem;

  @media (max-width: 1024px) {
    width: 100%;
    grid-row-start: ${({ order }) => (order * 3) - 1};
  }
`

const Descriptions = styled.div`
  @media (max-width: 1024px) {
    grid-row-start: ${({ order }) => order * 3};
  }
`;

const Grid = styled(Container)`
  margin: 1.5rem auto;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr 1fr 1fr;

  img {
    margin: auto;
    margin-block-end: 1rem;
    margin-block-start: 1rem;
  }

  ${Descriptions} {
    margin: 1.5rem;
  }

  @media (max-width: 1024px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
    width: 100%;
    padding: 0;
  }
`

const StyledP = styled(P)`
  font-family: Saira;
  font-size: .875rem;
  color: #595959;
  margin-block-end: 1rem;
  margin-block-start: 1rem;
  text-align: justify;

`

const IconContainer = styled.div`
  height: 10rem;

  ${Icon} {
    height: 100% !important;
    width: auto;
  }

  @media (max-width: 1024px) {
    grid-row-start: ${({ order }) => order === 1 ? 1 : (order * 3) - 2};
  }
`

const StyledStrong = styled.strong`
  display: block;
  text-align: center;
  font-weight: 700;
  color: #595959;
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-family: Blinker;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    max-width: 462px;
    margin: auto;
    margin-bottom: 2rem;
  }
`

export default () => {
  const { t } = useTranslation()

  const images = [ image1, image2, image3 ]

  return (
    <StyledSection>
      <Title>{t('features.ai.title')}</Title>
      <StyledH3>{t('features.ai.subtitle')}</StyledH3>
      <Grid>
        {
          images.map((image, i) => (
            <div key={`innovations-ai-images-${i}`}>
              <IconContainer order={i + 1}>
                <Img src={image} />
              </IconContainer>
              <Descriptions order={i + 1}>
                <StyledP>
                  <b>{t(`features.ai.icon-descriptions.${i}.title`)}</b>
                </StyledP>
                {
                  Object.keys(t(`features.ai.icon-descriptions.${i}.description`, { returnObjects: true })).map(key => (
                    <StyledP key={`innovations-ai-icon-descriptions-${i}-description-${key}`}>
                      {t(`features.ai.icon-descriptions.${i}.description.${key}`)}
                    </StyledP>
                  ))
                }
              </Descriptions>
            </div>
          ))
        }
      </Grid>
      <StyledStrong>{t('features.ai.info')}</StyledStrong>
      <NavButton leftIcon={<SendIcon />} primary to='/contact'>
        {t('features.ai.button')}
      </NavButton>
    </StyledSection>
  )
}
