import React from 'react'

import { Section } from '../components'
import Intro from '../modules/offers/Intro'
import Offers from '../modules/offers/Offers'
import Details from '../modules/offers/Details'

export default () => (
  <Section>
    <Intro />
    <Offers />
    <Details />
  </Section>
)
