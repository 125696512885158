
import React from 'react'

import { Section } from '../components'

import Intro from '../modules/features/Intro'
import Features from '../modules/features/Features'
import Ai from '../modules/features/Ai'

export default () => (
  <Section>
    <Intro />
    {/* <Features /> */}
    <Ai />
  </Section>
)
