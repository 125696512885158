import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  Section,
  H2,
  H3,
  Icon,
  Container,
  CheckCircleIcon,
} from '../../components'

const StyledSection = styled(Section)`
  padding: 1rem 0;
  text-align: center;
  max-width: 1280px;
  margin: auto;

  @media (max-width: 1024px) {
    padding: 0 10%;
  }
  @media (max-width: 600px) {
    padding: 0;
  }
`

const StyledH2 = styled(H2)`
  font-family: Blinker;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 2rem;
  font-size: 2.3rem;

  @media (min-width: 960px) {
    letter-spacing: 0.2em;
  }
`

const StyledH3 = styled(H3)`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;

`

const SubtitleH3 = styled(StyledH3)`
  @media (max-width: 960px) {
    width: 80%;
    margin: auto;
  }
`

const Grid = styled(Container)`
  margin: 1.5rem auto;
  display: flex;
  align-items: baseline;

  & > div {
    width: 100%;
  }

  & > div:nth-child(2n + 1) {
    margin-right: 1.5rem;
  }
  & > div:nth-child(2n + 2) {
    margin: 0 1.5rem;
  }
  & > div:nth-child(2n + 3) {
    margin-left: 1.5rem;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    max-width: 100%;

    & > div {
      margin: 1.5rem 0 !important;
    }
  }
`

const RowOffer = styled.div`
  font-family: Saira;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid #E5E5E5;
  }

  ${Icon} {
    height: 35px;
    width: auto;
    color: ${({ color }) => color};
  }
`

const ColumnOffer = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 15px 30px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div:nth-child(2) {
    height: 25rem;
  }
  & > div:nth-child(n + 3) {
    height: 10rem;
  }
`;

const ColumnHeader = styled.div`
  background-color: ${({ color }) => color};
  padding: 1rem 0;
  width: 100%;

  ${StyledH3} {
    margin-bottom: 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span, a {
    color: ${({ color }) => color};
    font-weight: bold;
  }
`

const RowContent = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding: 2rem 1rem;
  font-family: Saira;

  b {
    font-family: Saira;
  }
`

export default () => {
  const { t } = useTranslation()

  return (
    <StyledSection>
      <StyledH2>{t('offers.offers.title')}</StyledH2>
      <SubtitleH3>{t('offers.offers.subtitle')}</SubtitleH3>

      <Grid>
        <ColumnOffer>
          <ColumnHeader color='#50C5F3'>
            <StyledH3>{t('offers.offers.licenses.saas-volume.title')}</StyledH3>
          </ColumnHeader>
          <RowOffer>
            <RowContent>
              <b>{t('offers.offers.licenses.interface-mode')}</b>
              <br /><span>-</span><br />
              {t('offers.offers.licenses.mensual-sub')}
              <br /><br />
              <b>{t('offers.offers.licenses.api-mode')}</b>
              <br /><span>-</span><br />
              {t('offers.offers.licenses.saas-volume.cost')}
            </RowContent>
          </RowOffer>
          <RowOffer>
            <RowContent>
              <b>{t('offers.offers.licenses.saas-volume.standard-results')}</b>
              <br /><span>-</span><br />
              {t('offers.offers.licenses.saas-volume.results')}
            </RowContent>
          </RowOffer>
          <RowOffer>
            <RowContent>
              <b>{t('offers.offers.licenses.available-options')}</b>
              <br /><span>-</span><br />
              {t('offers.offers.licenses.saas-volume.options')}
            </RowContent>
          </RowOffer>
          <RowOffer>
            <RowContent>
              <b>{t('offers.offers.licenses.host')}</b>
              <br /><span>-</span><br />
              {t('offers.offers.licenses.distant-opening')}
            </RowContent>
          </RowOffer>
          <RowOffer>
            <RowContent>
              <b>{t('offers.offers.licenses.providing')}</b>
              <br /><span>-</span><br />
              {t('offers.offers.licenses.saas-volume.immediate')}
            </RowContent>
          </RowOffer>
        </ColumnOffer>
        <ColumnOffer>
          <ColumnHeader color='#144E95'>
            <StyledH3>{t('offers.offers.licenses.saas-project.title')}</StyledH3>
          </ColumnHeader>
          <RowOffer>
            <RowContent>
              <b>{t('offers.offers.licenses.interface-mode')}</b>
              <br /><span>-</span><br />
              {t('offers.offers.licenses.mensual-sub')}
              <br /><br />
              <b>{t('offers.offers.licenses.api-mode')}</b>
              <br /><span>-</span><br />
              {t('offers.offers.licenses.saas-project.cost')}
            </RowContent>
          </RowOffer>
          <RowOffer>
            <RowContent>
              <b>{t('offers.offers.licenses.guaranteed-results')}</b><br />
              <CheckCircleIcon />
            </RowContent>
          </RowOffer>
          <RowOffer>
            <RowContent>
              <b>{t('offers.offers.licenses.available-options')}</b>
              <br /><span>-</span><br />
              <a href='/license#available-options'>{t('offers.offers.licenses.all-options')}</a>
            </RowContent>
          </RowOffer>
          <RowOffer>
            <RowContent>
              <b>{t('offers.offers.licenses.host')}</b>
              <br /><span>-</span><br />
              {t('offers.offers.licenses.distant-opening')}
            </RowContent>
          </RowOffer>
          <RowOffer>
            <RowContent>
              <b>{t('offers.offers.licenses.providing')}</b>
              <br /><span>-</span><br />
              {t('offers.offers.licenses.under-nth-days', { nb: 15 })}
            </RowContent>
          </RowOffer>
        </ColumnOffer>
        <ColumnOffer>
          <ColumnHeader color='#CF6B46'>
            <StyledH3>{t('offers.offers.licenses.on-premise.title')}</StyledH3>
          </ColumnHeader>
          <RowOffer>
            <RowContent>
              <b>{t('offers.offers.licenses.interface-mode')}</b>
              <br /><span>-</span><br />
              {t('offers.offers.licenses.on-premise.cost')}
              <br /><br />
              <b>{t('offers.offers.licenses.api-mode')}</b>
              <br /><span>-</span><br />
              {t('offers.offers.licenses.on-premise.cost-page')}
            </RowContent>
          </RowOffer>
          <RowOffer>
            <RowContent>
              <b>{t('offers.offers.licenses.guaranteed-results')}</b><br />
              <CheckCircleIcon />
            </RowContent>
          </RowOffer>
          <RowOffer>
            <RowContent>
              <b>{t('offers.offers.licenses.available-options')}</b>
              <br /><span>-</span><br />
              <a href='/license#available-options'>{t('offers.offers.licenses.all-options')}</a>
            </RowContent>
          </RowOffer>
          <RowOffer>
            <RowContent>
              <b>{t('offers.offers.licenses.host')}</b>
              <br /><span>-</span><br />
              {t('offers.offers.licenses.on-premise.host')}
            </RowContent>
          </RowOffer>
          <RowOffer>
            <RowContent>
              <b>{t('offers.offers.licenses.providing')}</b>
              <br /><span>-</span><br />
              {t('offers.offers.licenses.under-nth-days', { nb: 60 })}
            </RowContent>
          </RowOffer>
        </ColumnOffer>
      </Grid>
    </StyledSection>
  )
}
