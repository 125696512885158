const innovations = {
  'intro': {
    'title': 'If other companies settle for being good, NeurOCR\'S AI aims for excellence!',
    'description': [
      'Innovation is at the heart of our corporate strategy; it is characterized by massive and constant investments in R&D and engineers who strive for excellence.',
      'This approach has allowed us to disrupt the optical character recognition (OCR) market, which was standardized until now. Indeed, NeurOCR includes a semantic analysis software layer based on Artificial Intelligence, allowing it to understand different business languages, in any industry!',
      'This advanced technique, both innovative and unprecedented, greatly improves the accuracy of the OCR process, and is known as ICR for "Intelligent Character Recognition".',
      'NeurOCR has a self-learning system that allows it to continuously improve its performance. This is how our innovative solution provides unparalleled results in terms of OCR quality for both handwritten and non-handwritten documents.',
      'The natural language processing (NLP) understanding layer combined with the data capture AI (OCR) makes NeurOCR significantly different from other solutions on the market.',
    ],
  },
  'content': {
    'title': 'This is also what allows it to be excellent in terms of processing complex documents from the industrial sector in particular.',
    'description': [
      'Its unique automated processing capability allows NeurOCR to intelligently extract data from documents without any human action other than quality control. NeurOCR also makes it possible to rebuild the business references of large format plans and technical nomenclatures, to compare the title blocks on a tremendous number of documents, to eliminate duplicates and triplicates while lightening the archives, etc. This innovative process will therefore fit perfectly into an Industry 4.0 approach where performance gains and concentration on high value-added tasks are the key words.',
      'Although industries represent the major part of NeurOCR users, its application scope is much wider. Indeed, NeurOCR can facilitate the elaboration and management of balance sheets and invoices, centralize all the documents of a legal file, retrieve order numbers over a given period, etc.',
    ],
    'title2': 'And the disruptive character of NeurOCR doesn\'t stop there!',
    'description2': [
      'While traditional methods are static and deal with documentary resources, NeurOCR breaks the rules and makes it possible to recognize characters in video formats (license plates, road signs, storefronts, etc.), in real time. Our solution can therefore be easily integrated into embedded systems, which is unique to this day!',
      'Do you want to digitize your documents or extract information from them in an automatic and targeted way?',
    ],
    'button': 'See our offers',
  },
}

export default innovations
