const footer = {
  'title': '{{app}} conçu par Neuronalys.',
  'subtitle': 'Une société française experte du domaine de l\'Intelligence Artificielle.',
  'description': [
    'Notre but : permettre l\'optimisation et l\'évolution des process métiers grâce à notre gamme logicielle d\'IA complète. Les solutions Neuronalys sont distribuées sur les secteurs de la sécurité, de l\'industrie, de la santé, du retail, du secteur public et des technologies de pointe.',
    'Parmi ses clients, l\'entreprise compte notamment des grands noms de l\'Aéronautique et de l\'Énergie, des universités et des forces de l\'ordre de différents pays.',
  ],
  'legal-notices': 'Mentions légales',
  'copyright': '© Neuronalys® 2021 - Tous droits réservés',
  'copyright-app': '{{app}}® est une marque déposée appartenant à Neuronalys®',
}

export default footer
