const features = {
  'intro': {
    'title': 'AI for your documentary resources',
    'description': [
      'NeurOCR – designed by Neuronalys, a French start-up specialized in Artificial Intelligence – is aimed at industries that want to make the most of their document resources.',
      'NeurOCR will allow you to digitize a large number of documents, from the simplest to the most technical, while extracting the information essential to your business in a targeted manner.',
      'Heavy calculation operations are performed by NeuroComputer, a "super-computer" (exclusively integrated in the OnPremise offer). Its incredible power ensures the speed and stability of operations in an open or sealed network.',
    ]
  },
  'features': {
    'title': 'Features',
    'videos': [
      {
        'title': 'Interface mode',
        'description': '',
      },
      {
        'title': 'API mode',
        'description': '',
      },
      {
        'title': 'Datasets and AI training',
        'description': '',
      },
      {
        'title': 'Complex cases',
        'description': '',
      },
      {
        'title': 'Export profiles',
        'description': '',
      },
      {
        'title': 'NeurOCR and interoperability',
        'description': '',
      },
      {
        'title': 'Details of the offers',
        'description': '',
      },
    ],
  },
  'ai': {
    'title': 'Practice makes perfect!',
    'subtitle': 'Basically, the NeurOCR AI is trained according to the following protocol:',
    'icon-descriptions': [
      {
        'title': '1 - Completeness: creation of a consistent dataset similar to the data to be identified in real conditions.',
        'description': [
            'First, we randomly merge the characters from our different datasets; these ones are constantly enriched as well as our models that are continuously updated.',
            'The resulting merged dataset can be enriched with part of the original documents to improve the quality of the recognition according to the state of the documents and the expectations. Normed, standardized, handwritten, typed or complex, NeurOCR\'s AI processes all types of characters in an image (numbers, letters, etc.), even badly scanned or partially deteriorated by time.',
        ]
      },
      {
        'title': '2 - Adaptability: image processing for difficult situations.',
        'description': [
          'In order to maintain the quality of the OCR process, the dataset can be subject to a set of rotations, resizing, noise addition and character sharpness variations.',
          'NeurOCR will be able to deal with the most complex drawings and badly scanned or partially deteriorated documents.',
        ]
      },
      {
        'title': '3 - Efficiency: implementation of different artificial neural networks.',
        'description': [
          'These high-performance convolutional networks will be connected to the dataset and optimized. Once the training is approved, the processing in real conditions can begin.',
          'In the most complex cases, specific training is carried out by including the target data of the project.',
        ]
      },
    ],
    'info': 'We are at your disposal for all requests',
    'button': 'Contact us',
  }
}

export default features
