import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import neurOCRLogo from '../../../../../public/images/neurOCR-logo.svg'

import {
  Section,
  P,
  H2,
  Container,
  Columns
} from '../../components'

const HomeSection = styled(Section)`
  position: relative;
  overflow: hidden;
  text-align: center;
  color: ${({ theme }) => theme.white};
  z-index: 0;

  @media (min-width: 1024px) {
    text-align: start;
  }
`

const HomeContainer = styled(Container)`
  padding: 0 4.5rem;
  padding-top: 1rem;
  padding-bottom: 5rem;
  margin: 2.5rem auto;
  display: flex;

  @media (max-width: 1024px) {
    padding: 1rem;
    padding-bottom: 3rem;
    margin-top: 0;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`

const Video = styled.video`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  min-width: 100%;
  min-height: 56.25vw;
  z-index: -5;
`

const NeurocrIcon = styled.img`
  filter: invert(1);
  width: 10rem;

  @media (max-width: 1024px) {
    width: 9rem;
    margin-bottom: 1.5rem;
  }
`

const HomeH2 = styled(H2)`
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 2rem;
  font-family: Blinker;

  @media (max-width: 1024px) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`

const StyledP = styled(P)`
  margin-bottom: 1rem;
`

const StrongP = styled(StyledP)`
  font-weight: 700;
`

const LeftTriangle = styled.div`
  display: inline-block;
  height: 0;
  width: 0;
  border-right : 960px solid transparent;
  border-bottom : 80px solid #FFF;
  position: absolute;
  left: 0;
  bottom: 0;

  @media (max-width: 1024px) {
    border-right: 512px solid transparent;
  }
  @media (max-width: 768px) {
    border-right: 384px solid transparent;
  }
  @media (max-width: 425px) {
    border-right: 212px solid transparent;
  }
  @media (max-width: 320px) {
    border-right: 160px solid transparent;
  }
`

const RightTriangle = styled.div`
  display: inline-block;
  height: 0;
  width: 0;
  border-left: 960px solid transparent;
  border-bottom : 80px solid #FFF;
  position: absolute;
  right: 0;
  bottom: 0;
  @media (max-width: 1024px) {
    border-left: 512px solid transparent;
  }
  @media (max-width: 768px) {
    border-left: 384px solid transparent;
  }
  @media (max-width: 425px) {
    border-left: 212px solid transparent;
  }
  @media (max-width: 320px) {
    border-left: 160px solid transparent;
  }
`

export default () => {
  const { t } = useTranslation()

  return (
    <HomeSection>
      <Video autoPlay='autoplay' loop='loop' muted playsInline poster='/images/mesh_poster.jpg'>
        <source src='videos/NeurOCR.mp4' type='video/mp4' />
      </Video>
      <LeftTriangle />
      <RightTriangle />
      <HomeContainer>
        <Columns nb={4}>
          <NeurocrIcon src={neurOCRLogo} />
        </Columns>
        <Columns nb={10}>
          <HomeH2>{t('home.intro.title')}</HomeH2>
          <StrongP>
            {t('home.intro.subtitle')}
          </StrongP>
          {
            Object.keys(t('home.intro.description', { returnObjects: true })).map(key => (
              <StyledP key={`home-intro-description-${key}`}>
                {t(`home.intro.description.${key}`)}
              </StyledP>
            ))
          }
        </Columns>
      </HomeContainer>
    </HomeSection>
  )
}
