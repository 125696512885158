import React, { createContext, useReducer } from 'react'
import axios  from 'axios'

const initialState = {}

const reducer = (state, { type, payload }) => {
  switch(type) {
    case 'SET_DEMO':
      return { ...state, demo: payload|| [] }
    default:
      throw new Error()
  }
}

const context = createContext(initialState);
const { Provider } = context;

export const DemoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const actions = {
    fetch: () =>
      axios.get('/api/test')
        .then(({ data }) => {
          dispatch({ type: 'SET_DEMO', payload: data })
          return data
        })
  }

  return <Provider value={[state, actions]}>{children}</Provider>
}

export default context
