import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import bg from '../../../../../public/images/background-innovation.png'
import {
  Section,
  P,
  NavButton,
  AngleRightIcon,
} from '../../components'

const InnovationsSection = styled.div`
  background-image: url(${({ src }) => src});
  background-size: cover;
  position: relative;

  @media (max-width: 1024px) {
    text-align: start;
  }
`

const BgOverlay = styled.div`
  background-color: #000;
  opacity: .6;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
`

const StyledSection = styled(Section)`
  max-width: 1280px;
  padding: 0;
  margin: auto;
`

const StyledP = styled(P)`
  margin: O;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.white};
`

const Grid = styled.div`
  /* margin-top: 4rem; */
  padding: 2rem 6rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  > div {
    z-index: 1;
    padding: 1rem 2rem;
    padding-bottom: 3rem;
  }

  @media (max-width: 1024px) {
    padding: 0;
    flex-direction: column;
    justify-content: center;
  }
`

const ButtonContainer = styled.div`
  margin: auto;
  margin-top: 4rem;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    text-align: center;
    margin-top: 2rem;
  }
`

const StyledH3 = styled.h3`
  color: ${({ theme }) => theme.primaryColor};
`

export default () => {
  const { t } = useTranslation()

  console.log(t('innovations.intro.title'))
  return (
    <InnovationsSection src={bg}>
      <StyledSection>
        <Grid>
          <div>
            <StyledH3>{t('innovations.content.title')}</StyledH3>
            {
              t('innovations.content.description', { returnObjects: true }).map((line, lineIndex) => {
                const allBoldSentences = [
                  /* FR */
                  'NeurOCR bouscule les codes et rend possible la reconnaissance de caractères dans les formats vidéo',
                  /* EN */
                  'NeurOCR breaks the rules and makes it possible to recognize characters in video formats',
                ]
                let foundBoldSentence = null
                foundBoldSentence = allBoldSentences.find(boldSentence => line.indexOf(boldSentence) !== -1)
                if (foundBoldSentence !== null) {
                  const splittedLine = line.split(foundBoldSentence)
                  return (
                    <StyledP key={lineIndex}>
                      {splittedLine[0]}
                      <b>{foundBoldSentence}</b>
                      {splittedLine[1]}
                    </StyledP>
                  )
                }
                return (
                  <StyledP key={lineIndex}>{line}</StyledP>
                )
              })
            }
            <StyledH3>{t('innovations.content.title2')}</StyledH3>
            {
              t('innovations.content.description2', { returnObjects: true }).map((line, lineIndex) => (
                  <StyledP key={lineIndex}>{line}</StyledP>
                )
              )
            }
            <ButtonContainer>
              <NavButton rightIcon={<AngleRightIcon />} primary to='/offers'>
                {t('innovations.content.button')}
              </NavButton>
            </ButtonContainer>
          </div>
        </Grid>
      </StyledSection>
      <BgOverlay />
    </InnovationsSection>
  )
}
