import React from 'react'

import { Section } from '../components'
import Intro from '../modules/home/Intro'
import Industries from '../modules/home/Industries'
import Solutions from '../modules/home/Solutions'
import Footer from '../modules/home/Footer'

export default () => (
  <Section>
    <Intro />
    <Solutions />
    <Industries />
    <Footer />
  </Section>
)
