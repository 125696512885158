import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  Section,
  Container,
  Columns,
  H2,
  P,
  Img,
} from '../../components'

import neurOCRLogo from '../../../../../public/images/neurOCR-logo-only.svg'

const StyledSection = styled(Section)`
  padding: 2rem 0;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.black};
`

const FlexContainer = styled(Container)`
  display: flex;
  flex-direction: column-reverse;
  text-align: center;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-end;
    text-align: left;
  }
`

const NeurocrIcon = styled.img`
  width: 128px;
  margin: auto;
  filter: invert(1);
`

const MeshImg = styled(Img)`
  position: absolute;
  width: 280px;
  height: 239px;
  top: 0;
  left: 0;

  @media (max-width: 768px) {
    left: -5%;
  }

  @media (max-width: 425px) {
    left: -35%;
  }

  @media (max-width: 375px) {
    left: -42%;
  }

  @media (max-width: 320px) {
    left: -60%;
  }
`

const PCImg = styled(Img)`
  max-width: 300px;
`

const HomeH2 = styled(H2)`
  font-size: 1.5rem;
  font-family: Blinker;
  font-weight: 700;
  letter-spacing: 0;
  font-size: 1.5rem;
  margin: 0 0 1rem;
  color: ${({ theme }) => theme.white};

  @media (min-width: 960px) {
    text-align: start;
  }
`

const HomeP = styled(P)`
  color: ${({ theme }) => theme.white};
  margin: 0 0 1rem 0;
`

const StyledColumns = styled(Columns)`
  z-index: 1;
`;

export default () => {
  const { t } = useTranslation()

  return (
    <StyledSection>
      <FlexContainer>
        <Columns nb={5} >
          <MeshImg src='/images/maillage_vert.png' alt='mesh' />
          <PCImg src='/images/computer_neurocr.png' alt='Neurocop super-ordinateur' />
        </Columns>
        <StyledColumns nb={7}>
          <NeurocrIcon src={neurOCRLogo} />
          <HomeH2>{t('features.intro.title')}</HomeH2>
          {
            t('features.intro.description', { returnObjects: true }).map((description, i) => (
              <HomeP key={`features-intro-desc-${i}`}>{description}</HomeP>
            ))
          }
        </StyledColumns>
      </FlexContainer>
    </StyledSection>
  )
}
