import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  Icon,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavbarCollapse,
  NavbarNav,
  NavItem,
  NavLink,
  SendIcon,
  BurgerMenuIcon,
  H4
} from '../components'

import neurOCRLogo from '../../../../public/images/neurOCR-logo-only.svg'

const Container = styled.div`
  position: relative;
  z-index: 5;
`

const NavigationBurgerMenuIcon = styled(BurgerMenuIcon)`
  color: ${({ theme }) => theme.primaryColor};
  width: 58px;
  height: 14px;
`

const StyledNavbarBrand = styled(NavbarBrand)`
  color: ${({ theme }) => theme.white};
  padding: 0.8125rem 0.3rem;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  column-gap: 5px;
`

const Space = styled.div`
  flex: 1;

  @media (max-width: 992px) {
    display: none;
  }
`

const SpaceMobile = styled.div`
  display: none;
  flex: 1;

  @media (max-width: 992px) {
    display: block;
  }
`

const StyledNavbarNav = styled(NavbarNav)`
  width: 100%;
`

const LanguagesContainer = styled.div`
  display: flex;
  column-gap: .4rem;
`

const Language = styled.img`
  height: 1.5rem;
  width: auto;
  align-self: center;
  cursor: pointer;
  opacity: ${({ selected }) => selected ? '1' : '0.4'};
  padding: 0 0.2rem;
`

const NeurocrIcon = styled.img`
  height: 30px;
  width: auto;
  margin: auto;
  filter: invert();
`

const StyledNavbar = styled(Navbar)`
  flex-wrap: nowrap;
`

const ContactNavItem = styled(NavItem)`
  display: flex;
  align-items: center;

  ${Icon} {
    color: ${({ theme }) => theme.primaryColor};
  }

  @media (max-width: 993px) {
    ${NavLink} {
      margin-right: 0;
    }
  }
`

const LoginNavItem =  styled(NavItem)`
  ${NavLink} {
    margin-right: 2rem;
  }
`

export default () => {
  const { t, i18n } = useTranslation()

  const [collapsed, setCollapsed] = useState(true)
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language)
  const el = useRef(null)

  const handleClick = (e) => {
    if (el.current.contains(e.target))
      setCollapsed(!collapsed)
    else
      setCollapsed(true)
  }

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () =>
      document.removeEventListener('click', handleClick, true);
  }, [collapsed])

  const changeLang = newLanguage => {
    i18n.changeLanguage(newLanguage, () => {
      setCurrentLanguage(newLanguage)
    })
  }

  return (
    <Container>
      <StyledNavbar>
        <StyledNavbarBrand to="/home">
          <NeurocrIcon src={neurOCRLogo} />
          <H4>NeurOCR</H4>
        </StyledNavbarBrand>
        <SpaceMobile />

        <NavbarCollapse collapsed={collapsed}>
          <StyledNavbarNav>
            <NavItem>
              <NavLink to="/innovations">{t('header.innovations')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/features">{t('header.features')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/offers">{t('header.offers')}</NavLink>
            </NavItem>
            <Space collapsed={!collapsed} />
            <ContactNavItem>
              <SendIcon />
              <NavLink to="/contact">{t('header.contact')}</NavLink>
            </ContactNavItem>
            <LoginNavItem>
              <NavLink as='a' href='https://app.neurocr.ai/' target='_blank'>{t('header.login')}</NavLink>
            </LoginNavItem>
          </StyledNavbarNav>
        </NavbarCollapse>

        <NavbarToggler ref={el}>
          <NavigationBurgerMenuIcon />
        </NavbarToggler>
        <LanguagesContainer>
          <Language selected={currentLanguage === 'fr'} onClick={() => changeLang('fr')} src={`/images/fr.svg`} />
          <Language selected={currentLanguage === 'en'} onClick={() => changeLang('en')} src={`/images/en.svg`} />
        </LanguagesContainer>
      </StyledNavbar>
    </Container>
  )
}
