const innovations = {
  'intro': {
    'title': 'Si d\'autres acteurs se contentent d\'être bons, l\'IA de NeurOCR vise l\'excellence !',
    'description': [
      'L\'innovation est au cœur de notre stratégie d\'entreprise, elle se caractérise par des investissements massifs et constants en R&D ainsi qu\'une volonté d\'excellence de la part de nos ingénieurs.',
      'Cette approche nous a permis de disrupter le marché de la reconnaissance optique de caractères (OCR), pour le moins standardisé. Effectivement, NeurOCR comporte une couche logicielle d\'analyse sémantique basée sur l\'Intelligence Artificielle, lui permettant de comprendre différents langages métiers et ce, quel que soit le secteur d\'activité concerné !',
      'Cette technique évoluée, à la fois innovante et inédite, améliore grandement la précision de l\'océrisation. On ne parle donc plus d\'OCR, mais bel et bien d\'ICR pour « Reconnaissance Intelligente de Caractères ».',
      'Les contenus élaborés n\'ont plus de secret pour NeurOCR et ce, grâce à l\'intégration d\'un système d\'auto-apprentissage lui permettant d\'améliorer ses performances en continu. C\'est ainsi que notre solution innovante affiche des résultats sans égal en termes de qualité d\'océrisation, tant sur les documents manuscrits que non-manuscrits.',
      'La couche de compréhension du langage naturel par programmation (NLP) corrélée à l\'IA de captation des données (OCR) permet à NeurOCR de se distinguer considérablement des autres solutions présentes sur le marché.',
    ],
  },
  'content': {
    'title': 'C\'est également ce qui lui permet d\'exceller en termes de valorisation de documents complexes issus, notamment, du secteur industriel.',
    'description': [
      'Sa capacité unique de traitement automatisé lui permet d\'extraire intelligemment les données des documents sans action humaine autre que le contrôle qualité. NeurOCR permet également, entre autres, de reconstruire les références métiers des plans grands formats et nomenclatures techniques, de comparer les cartouches sur une somme colossale de documents, de supprimer doublons et triplons tout en allégeant les archives, etc. Ce process innovant s’intègrera donc parfaitement dans une dynamique d’industrie 4.0 où le gain de performance et la concentration sur les tâches à forte valeur ajoutée sont les maîtres mots.',
      'En dehors du secteur industriel, NeurOCR peut tout à fait faciliter l\'élaboration et la gestion de bilans comptables et factures, permettre la centralisation de l\'ensemble des pièces d\'un dossier juridique, récupérer des numéros de commandes sur une pédiode donnée, etc.',
    ],
    'title2': 'Et le caractère disruptif de NeurOCR ne s\'arrête pas là !',
    'description2': [
      'Alors que les méthodes traditionnelles se veulent statiques et concernent la sphère documentaire, NeurOCR bouscule les codes et rend possible la reconnaissance de caractères dans les formats vidéo (plaques d\'immatriculation, jalonnement, devantures, etc.) et qui plus est, en temps réel. Notre solution peut donc être très facilement intégrée aux systèmes embarqués, ce qui est unique à ce jour !',
      'Vous souhaitez digitaliser vos documents ou en extraire l\'information de façon automatique et ciblée ?',
    ],
    'button': 'Consultez nos offres',
  },
}

export default innovations
