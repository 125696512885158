import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  Section,
  H2,
  H3,
  CheckIcon,
  SendIcon,
  NavButton,
  Container,
} from '../../components'

import footerImg from '../../../../../public/images/neurocomputer_black.png'

const StyledSection = styled(Section)`
  padding: 1rem 0;
  padding-bottom: 0;
  text-align: center;

  b {
    font-size: .9rem;
  }
`

const Grid = styled(Container)`
  margin: 1.5rem auto;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding-bottom: 2rem;

  img {
    margin: auto;
    margin-bottom: 2rem;
  }

  @media (max-width: 1024px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
    width: 100%;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`

const StyledUl = styled.ul`
  list-style: none;
  padding: 0;

  @media (max-width: 1024px) {
    grid-row-start: ${({ order }) => order * 3};
  }

  @media (min-width: 960px) {
    flex: 1;
  }
  `

const Li = styled.li`
  margin-bottom: 1.5rem;
  text-align: start;
  position: relative;
`

const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  top: 4px;
  color: #23DF21;
  margin-right: 1rem;
`

const P = styled.p`
  padding-left: 1.25rem;
  font-family: Saira;
  font-size: 0.875rem;
`

const StyledH3 = styled(H3)`
  font-size: 1.5rem;
  margin-bottom: 2rem;

  @media (max-width: 1024px) {
    grid-row-start: ${({ order }) => (order * 3) - 1};
  }
`

const StyledP = styled(P)`
  margin: O;
  margin-block-end: 1rem;
  margin-block-start: 1rem;
  color: #000;
  text-align: left;
`

const Seperator = styled.div`
  height: 100%;
  width: 1px;
  background-color: #E5E5E5;
  margin: 0 2rem;

  @media (max-width: 1024px) {
    margin: 2rem 0;
    height: 1px;
    width: 100%;
  }
`

const StyledButton = styled(NavButton)`
  margin-bottom: 2rem;
`

const LeftTriangle = styled.div`
  display: inline-block;
  height: 0;
  width: 0;
  border-right: 960px solid transparent;
  border-top: 80px solid #FFF;
  position: absolute;
  left: 0;
  top: 0;

  @media (max-width: 1024px) {
    border-right: 512px solid transparent;
  }
  @media (max-width: 768px) {
    border-right: 384px solid transparent;
  }
  @media (max-width: 425px) {
    border-right: 212px solid transparent;
  }
  @media (max-width: 320px) {
    border-right: 160px solid transparent;
  }
`

const RightTriangle = styled.div`
  display: inline-block;
  height: 0;
  width: 0;
  border-left: 960px solid transparent;
  border-top : 80px solid #FFF;
  position: absolute;
  right: 0;
  top: 0;

  @media (max-width: 1024px) {
    border-left: 512px solid transparent;
  }
  @media (max-width: 768px) {
    border-left: 384px solid transparent;
  }
  @media (max-width: 425px) {
    border-left: 212px solid transparent;
  }
  @media (max-width: 320px) {
    border-left: 160px solid transparent;
  }
`

const Footer = styled.div`
  position: relative;
  background-color: black;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-image: url(${footerImg});
  background-size: 30rem;
  background-repeat: no-repeat;

  @media (max-width: 1024px) {
    height: auto;
    padding-bottom: 3rem;
    background: black;
  }
`

const FooterContent = styled.div`
  margin-top: 5rem;
  margin-left: 30%;
  width: 60%;
  padding-left: 2rem;
  max-width: 1280px;

  ${StyledH3} {
    text-align: left;
  }

  p {
    color: #fff;
    padding-left: 0;
  }

  @media (max-width: 1024px) {
    margin: auto;
    margin-top: 7rem;
  }
`

const Mesh = styled.img`
  height: 17rem;
  width: auto;
  filter: invert(1);
  position: absolute;
  right: 50px;
  top: 0;
`

export default () => {
  const { t } = useTranslation()

  return (
    <StyledSection id='available-options'>
      <Grid>
        <div>
          <StyledH3 as='div'>{t('offers.details.details.title')}</StyledH3>
          <StyledP>
            <b>{t('offers.details.details.saas-offer1')} </b>
            {t('offers.details.details.saas-offer2')}
          </StyledP>
          <StyledP>
            <b>{t('offers.details.details.other-offers1')} </b>
            {t('offers.details.details.other-offers2')}
          </StyledP>
          <StyledP>
            <b>{t('offers.details.details.results1')} </b>
            {t('offers.details.details.results2')}
          </StyledP>
          <StyledP>
            <b>{t('offers.details.details.limits1')} </b>
            {t('offers.details.details.limits2')}
          </StyledP>
          <StyledP>
            {t('offers.details.details.communications1')}
            <b> {t('offers.details.details.communications2')} </b>
            {t('offers.details.details.communications3')}
          </StyledP>
        </div>
        <Seperator />
        <div>
          <StyledH3 as='div'>{t('offers.details.options.title')}</StyledH3>
          <StyledUl order={1}>
            <Li>
              <StyledCheckIcon />
              <P>
                <b>{t('offers.details.options.self-learning1')} </b>
                {t('offers.details.options.self-learning2')}
              </P>
            </Li>
            <Li>
              <StyledCheckIcon />
              <P>
                <b>{t('offers.details.options.template-export1')} </b>
                {t('offers.details.options.template-export2')}
              </P>
            </Li>
            <Li>
              <StyledCheckIcon />
              <P>
                <b>{t('offers.details.options.template-matching1')} </b>
                {t('offers.details.options.template-matching2')}
              </P>
            </Li>
            <Li>
              <StyledCheckIcon />
              <P>
                <b>{t('offers.details.options.search-engine1')} </b>
                {t('offers.details.options.search-engine2')}
              </P>
            </Li>
            <Li>
              <StyledCheckIcon />
              <P>
                <b>{t('offers.details.options.rotation1')} </b>
                {t('offers.details.options.rotation2')}
              </P>
            </Li>
            <Li>
              <StyledCheckIcon />
              <P>
                <b>{t('offers.details.options.scan1')} </b>
                {t('offers.details.options.scan2')}
              </P>
            </Li>
          </StyledUl>
        </div>
      </Grid>
      <StyledButton leftIcon={<SendIcon />} primary to='/contact'>
        {t('offers.details.contact')}
      </StyledButton>
      <Footer>
        <Mesh src='/images/footer-mesh.svg' alt='mesh' />
        <RightTriangle />
        <LeftTriangle />
        <FooterContent>
          <StyledH3 as='div'>NeuroComputer</StyledH3>
          <StyledP>
            {t('offers.details.neurocomputer1') + ' '}
            <b>{t('offers.details.neurocomputer2')}</b>
          </StyledP>
        </FooterContent>
      </Footer>
    </StyledSection>
  )
}
