const footer = {
  'title': '{{app}} designed by Neuronalys.',
  'subtitle': 'A French start-up specialized in Artificial Intelligence.',
  'description': [
    'Our aim: to enable the optimization and evolution of business processes thanks to our full range of AI software. Neuronalys solutions are distributed in various industries such as security, industry, health, retail, public sector and cutting-edge technologies.',
    'Neuronalys\' customers include leading companies in Aeronautics and Energy, universities and law enforcement agencies in various countries.',
  ],
  'legal-notices': 'Terms of use',
  'copyright': '© Neuronalys® 2021 - All rights reserved',
  'copyright-app': '{{app}}® is a registered trademark of Neuronalys®',
}

export default footer
