import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  Section,
  Container,
  H2,
  PlayCircleIcon,
  Icon,
  AngleDownIcon,
} from '../../components'

const InnovationsFeaturesContainer = styled.div`
  position: relative;
  background-color: #000;
`

const StyledContainer = styled(Container)`
  padding: 4rem 0;
`

const StyledSection = styled(Section)`
  padding: 2rem 1rem;
  text-align: center;
  position: relative;
  color: #fff;

  ${Icon} {
    height: 1.7rem;
    width: auto;
    color: ${({ theme }) => theme.primaryColor};
  }
`

const Mesh = styled.img`
  position: absolute;
  top: -50px;
  right: 0;
  width: 309px;
  height: 249px;
  transform: rotate(180deg);
`

const Title = styled(H2)`
  font-family: Blinker;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 2rem;
  font-size: 2.3rem;
  position: relative;

  @media (max-width: 1024px) {
    letter-spacing: 0.2em;
    font-size: 1.5rem;
  }
`

const VideoContainer = styled.div`
  display: flex;
  margin-top: 3rem;

  iframe {
    width: 100%;
    height: 400px;
  }
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`

const Img = styled.img`
  width: 100%;
`

const VideoList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 50%;
  list-style-type: none;
  text-align: left;
  padding: 0;
  margin: 0;
  margin-left: 1rem;

  @media (max-width: 1024px) {
    display: none;
  }
`

const VideoListEl = styled.li`
  display: flex;
  align-items: center;
  font-family: Blinker;
  font-size: 1.3rem;
  width: 100%;
  flex-grow: 1;
  color: ${({ selected }) => selected ? '#fff' : '#999'};
  cursor: pointer;

  &:not(:first-child) {
    border-top: 1px solid #fff;
  }

  ${Icon} {
    margin-right: 1rem;
    min-width: 2rem;
  }
`

const VideoDescription = styled.div`
  font-family: Blinker;
  font-size: 1.1rem;
`

const StyledAngleDownIcon = styled(AngleDownIcon)`
  display: block;
  margin: auto;

  @media (max-width: 1024px) {
    display: none;
  }
`

const CustomSelectContainer = styled.div`
  position: relative;

  @media (min-width: 1024px) {
    display: none;
  }
`

const CustomSelect = styled.div`
  user-select: none;
  background-color: #595959;
  color: #fff;
  border-radius: 5px;
  width: calc(100% - (18px));
  display: flex;
  align-items: center;
  padding: 9px;
  font-family: Saira;

  ${Icon} {
    position: absolute;
    right: 1rem;
  }
`

const CustomOptionContainer = styled.div`
  user-select: none;
  position: absolute;
  margin-top: 3px;
  background-color: #595959;
  color: #fff;
  border-radius: 5px;
  width: calc(100% - (18px));
  padding: 9px;
  text-align: left;
  display: ${({ openSelect }) => openSelect ? 'block' : 'none'};
`

const CustomOption = styled.div`
  padding: 5px 0;
`

const MediaContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
`

const MySelect = ({ t, openSelect, currentVideo, setOpenSelect, setCurrentVideo, videos }) => {
  const wrapperRef = useRef(null);

  // used to see if click is outside of component
  const useOutsideAlerter = ref => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenSelect(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  return (
    <CustomSelectContainer ref={wrapperRef} onClick={() => setOpenSelect(!openSelect)}>
      <CustomSelect>
        {t(`features.features.videos.${currentVideo}.title`)}
        <AngleDownIcon />
      </CustomSelect>
      <CustomOptionContainer openSelect={openSelect}>
        {
          videos.map((e, i) => (
            <CustomOption
              onClick={() => setCurrentVideo(i)}
              key={`innovations-videos-select-${i}`}
            >
              {t(`features.features.videos.${i}.title`)}
            </CustomOption>
          ))
        }
      </CustomOptionContainer>
    </CustomSelectContainer>
  )
}

export default () => {
  const [currentVideo, setCurrentVideo] = useState(0)
  const [openSelect, setOpenSelect] = useState(false)

  const { t } = useTranslation()

  const videos = [
    { type: 'video', src: 'https://www.youtube.com/embed/UwsrzCVZAb8' },
    { type: 'video', src: 'https://www.youtube.com/embed/UwsrzCVZAb8' },
    { type: 'video', src: 'https://www.youtube.com/embed/UwsrzCVZAb8' },
    { type: 'video', src: 'https://www.youtube.com/embed/UwsrzCVZAb8' },
    { type: 'video', src: 'https://www.youtube.com/embed/UwsrzCVZAb8' },
    { type: 'video', src: 'https://www.youtube.com/embed/UwsrzCVZAb8' },
  ]

  return (
    <InnovationsFeaturesContainer>
      <Mesh src='/images/maillage_vert.png' />
      <StyledContainer>
        <StyledSection>
          <Title>{t('features.features.title')}</Title>
          <StyledAngleDownIcon />
          <VideoContainer>
            <MySelect
              t={t}
              openSelect={openSelect}
              currentVideo={currentVideo}
              setOpenSelect={setOpenSelect}
              setCurrentVideo={setCurrentVideo}
              videos={videos}
            />
            <MediaContainer>
              {
                videos[currentVideo].type === 'video' ?
                  (
                    <iframe
                      src={videos[currentVideo].src}
                      frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen
                    />
                  ) : (
                    <Img src={videos[currentVideo].src} />
                  )
              }
              <VideoDescription>
                {t(`features.features.videos.${currentVideo}.description`)}
              </VideoDescription>
            </MediaContainer>
            <VideoList>
              {
                Object.keys(videos).map((key, i) => (
                  <VideoListEl
                    key={`innovations-videos-${i}`}
                    selected={currentVideo === i}
                    onClick={() => setCurrentVideo(i)}
                  >
                    <PlayCircleIcon />
                    {t(`features.features.videos.${i}.title`)}
                  </VideoListEl>
                ))
              }
            </VideoList>
          </VideoContainer>
        </StyledSection>
      </StyledContainer>
    </InnovationsFeaturesContainer>
  )
}
