const terms = {
  'intro': {
    'title': 'MENTIONS LÉGALES',
    'description': [
      'Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs du site internet de NeurOCR®, les présentes mentions légales.',
      'Le site internet de NeurOCR® est accessible à l’adresse suivante : www.neurocr.ai.',
      'Dès lors que l’utilisateur accède au site internet de NeurOCR®, cela implique l’acceptation intégrale et sans réserve de la part de celui-ci à l’égard des lois et/ou règlements applicables en vigueur ainsi qu’aux présentes mentions légales détaillées ci-après.',
      'Nous préciserons que le terme « utilisateur » se réfère à tous les internautes qui accèdent, se connectent, naviguent, lisent, visionnent et utilisent le site internet de NeurOCR®.',
    ],
  },
  'content': {
    'categories': [
      {
        'title': 'ARTICLE 1 : INFORMATIONS LÉGALES',
        'sections': [
          {
            'title': 'a) L\'éditeur :',
            'content': [
              'L\'édition du site www.neurocr.ai est assurée par la Société SAS Neuronalys® au capital de 52 633 euros, immatriculée au RCS de Lille Métropole sous le numéro 853145845.',
              'Son siège social est situé au 104 rue nationale, 59 800 Lille.',
              'Numéro de téléphone : +33 3 74 09 66 64.',
              'Adresse email : contact@neuronalys.ai.',
              'Son numéro de TVA intracommunautaire est le suivant : FR09853145845.',
            ],
          },
          {
            'title': 'b) Le directeur de publication :',
            'content': [
              'Le directeur de publication du site internet www.neurocr.ai est Fabien GAVORY. Il peut être contacté à l’adresse email suivante : contact@neuronalys.ai.',
            ]
          },
          {
            'title': 'c) L\'hébergeur :',
            'content': [
              'L\'hébergeur du site www.neurocr.ai est la société Digital Ocean.',
              'Son siège social est situé au New York, NY. 101 6th Ave.',
              'Numéro de téléphone : +1 347-903-7918.',
            ]
          },
        ],
      },
      {
        'title': 'ARTICLE 2 : OBJET DU SITE',
        'sections': [
          {
            'title': '',
            'content': [
              'Le présent site internet est destiné à fournir des renseignements gratuits et relatifs à Neuronalys®, son produit NeurOCR® et les services y étant associés. Par conséquent, les informations qu’il contient étant proposées à titre informatif, ne sont alors pas contractuelles et ne créent pas de relations commerciales entre Neuronalys® et l’utilisateur. Pour conclure toute forme d’accord, veuillez contacter directement la société aux coordonnées susmentionnées.',
              'De surcroît, Neuronalys® n’assume aucune responsabilité, obligation ou engagement pour toute erreur ou omission dans le contenu du présent site.',
              'Neuronalys® décline toute responsabilité pour tout dommage – qu’il soit indirect, consécutif ou accessoire – y compris, mais sans s’y limiter, la perte de profits ou de revenus, l’interruption des activités et la perte de données découlant de – ou en relation avec – l’utilisation, l’incapacité à utiliser ou la confiance accordée à tout contenu de ce site ou à tout site lié pouvant résulter de l’accès à ce site, et de l’utilisation totale ou partielle des éléments ou des informations qui y sont contenues.',
              'Par ailleurs, Neuronalys® se réserve le droit de modifier et de mettre à jour, à tout moment et sans préavis, les contenus ou toute autre information publiés sur le site www.neurocr.ai.'
            ]
          },
        ],
      },
      {
        'title': 'ARTICLE 3 : ACCESSIBILITÉ',
        'sections': [
          {
            'title': '',
            'content': [
              'Le site internet de NeurOCR® est, par principe, accessible aux utilisateurs par tout endroit, 7 jours sur 7, 24 heures sur 24, sauf interruption pour des besoins de maintenance (programmée ou non) ou en cas de force majeure.',
              'Dans le cas d’une non-accessibilité au site internet de NeurOCR®, Neuronalys® s’engage à faire de son mieux en vue d’en rétablir l’accès le plus rapidement possible.',
              'Néanmoins, le site internet www.neurocr.ai ne saurait être tenu pour responsable de tout dommage – quelle qu’en soit la nature – résultant de son indisponibilité.',
            ]
          },
        ],
      },
      {
        'title': 'ARTICLE 4 : COLLECTE ET PROTECTION DES DONNÉES PERSONNELLES',
        'sections': [
          {
            'title': '',
            'content': [
              'La sécurité de vos données personnelles fait l’objet de toute l’attention de Neuronalys® qui prend leur protection très au sérieux.',
              'Ainsi, le site de NeurOCR® assure à l\'utilisateur une collecte et un traitement d\'informations personnelles dans le respect de la vie privée conformément à la Loi n°78-17 du 6 janvier 1978 relative à l\'informatique, aux fichiers et aux libertés et au Règlement Général sur la Protection des Données (RGPD) n°2016/679 du Parlement Européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données.',
              'Neuronalys® informe les utilisateurs du site internet www.neurocr.ai que toute donnée personnelle recueillie via le formulaire de contact ne sera traitée que dans un cadre strictement et purement professionnel (relation commerciale potentielle).',
              'Par ailleurs, toujours en vertu de la loi et du règlement susvisés, l\'utilisateur dispose d\'un droit d\'accès, de rectification, de suppression et d\'opposition de ses données personnelles. L\'utilisateur peut exercer ce droit par email à l\'adresse suivante : contact@neuronalys.ai ou par courrier recommandé avec accusé de réception adressé à Neuronalys® aux coordonnées susmentionnées (Art. 1 – a).',
            ]
          },
        ],
      },
      {
        'title': 'ARTICLE 5 : COOKIES',
        'sections': [
          {
            'title': '',
            'content': [
              'Un cookie est un élément qui ne permet pas d’identifier l’utilisateur mais sert à enregistrer des informations relatives à la navigation de celui-ci sur le site internet. L’utilisateur pourra désactiver ce cookie par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.',
              'Le site www.neurocr.ai peut être amené à vous demander l’acceptation des cookies pour des besoins de statistiques et d’affichage.',
              'Pour information, seul des cookies de type « Google Analytics » sont utilisés sur le dit site et ce, à des fins statistiques en vue de mieux comprendre le comportement des utilisateurs (analyse des tendances, nombre de visites, temps passé sur chaque page/rubrique, parcours utilisateur, etc.) en vue d’améliorer notre communication et nos services.',
              'En conséquence, nous traitons les données collectées par les cookies comme des données non personnelles.',
            ]
          },
        ],
      },
      {
        'title': 'ARTICLE 6 : PROPRIÉTÉ INTELLECTUELLE, LOI APPLICABLE ET JURIDICTION',
        'sections': [
          {
            'title': 'a) Le site www.neurocr.ai :',
            'content': [
              'Sauf mention contraire expresse, l’ensemble du site internet www.neurocr.ai – contenu et présentation – constitue une œuvre protégée par la législation française et internationale en matière de propriété intellectuelle et industrielle et est la propriété exclusive de Neuronalys®.',
              'Tout matériel qu’il contient – y compris, mais sans s’y limiter, les textes, graphiques, informations, motifs, logos, designs, mises en page, bases de données, images, sons, vidéos, icônes, noms de domaine, codes html – est protégé par la législation susmentionnée et reste la propriété de Neuronalys®.',
              'Toute utilisation, emprunt, reproduction, diffusion, commercialisation ou modification de toute ou partie du contenu du site internet www.neurocr.ai, sans l’autorisation écrite au préalable de l’éditeur est prohibée et pourra entraînée des actions et poursuites judiciaires, notamment prévues par le Code de la Propriété Intellectuelle et le Code Civil.',
            ]
          },
          {
            'title': 'b) Marques déposées :',
            'content': [
              'Les marques Neuronalys® et NeurOCR® sont deux marques déposées auprès de l’Institut National de la Propriété Industrielle (INPI). Elles ont pour numéro d’enregistrement respectif : 18108455 et 18109776.',
              'Toute utilisation, modification ou emprunt des marques susvisées sans un accord écrit de la part de Neuronalys® est strictement interdit et pourra entraîner des poursuites judiciaires.',
            ]
          },
          {
            'title': 'c) Législation applicable :',
            'content': [
              'Le site internet www.neurocr.ai et les marques déposées Neuronalys® et NeurOCR® sont régis par la loi française.',
              'À défaut de résolution amiable, tout litige relatif à l’interprétation, l’exécution ou la résiliation des présentes est soumis aux tribunaux légalement compétents.',
            ]
          },
        ],
      },
      {
        'title': 'ARTICLE 7 : CONTACT',
        'sections': [
          {
            'title': '',
            'content': [
              'Pour tout signalement de contenus ou d’activités illicites, l’utilisateur peut contacter l’éditeur à l’adresse email suivante : contact@neuronalys.ai, ou par courrier recommandé avec accusé de réception adressé à l’éditeur aux coordonnées susmentionnées (Art. 1 – a).',
              'Par ailleurs, à ces mêmes coordonnées, l’utilisateur peut faire part à Neuronalys® de toutes ses inquiétudes concernant le traitement de ses données et signaler une possible violation du droit relatif aux données personnelles.',
              'Neuronalys® s’engage à répondre aux requêtes de ses utilisateurs dans les meilleurs délais. Il est possible que des informations supplémentaires soient demandées à l’utilisateur dans le cas où son interrogation nécessiterait un approfondissement.',
              'Dans l’hypothèse où la réponse apportée par Neuronalys® ne satisferait pas l’utilisateur, nous l’invitons à adresser toute réclamation à la CNIL.',
              'Neuronalys® vous souhaite une excellente navigation sur le site www.neurocr.ai !',
            ]
          },
        ],
      },
    ]
  },
}

export default terms
