
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import footerImg from '../../../../../public/images/homepage/bg_disruptivite.jpg'

import {
  H3,
  P,
} from '../../components'

const StyledH3 = styled(H3)`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  line-height: 1.5rem;

  grid-column-start: ${({ order }) => {
    if (order < 3) return order + 1
    if (order === 3) return 0
    return order - 2
  }};
  grid-row-start: ${({ order }) => {
    if (order < 3) return 1
    return 2
  }};
`

const StyledP = styled(P)`
  font-family: Saira;
  font-size: 0.875rem;

  @media (max-width: 1024px) {
    grid-row-start: ${({ order }) => order * 3};
  }
`

const LeftTriangle = styled.div`
  display: inline-block;
  height: 0;
  width: 0;
  border-right: 960px solid transparent;
  border-top: 80px solid #FFF;
  position: absolute;
  left: 0;
  top: 0;

  @media (max-width: 1024px) {
    border-right: 512px solid transparent;
  }
  @media (max-width: 768px) {
    border-right: 384px solid transparent;
  }
  @media (max-width: 425px) {
    border-right: 212px solid transparent;
  }
  @media (max-width: 320px) {
    border-right: 160px solid transparent;
  }
`

const RightTriangle = styled.div`
  display: inline-block;
  height: 0;
  width: 0;
  border-left: 960px solid transparent;
  border-top : 80px solid #FFF;
  position: absolute;
  right: 0;
  top: 0;

  @media (max-width: 1024px) {
    border-left: 512px solid transparent;
  }
  @media (max-width: 768px) {
    border-left: 384px solid transparent;
  }
  @media (max-width: 425px) {
    border-left: 212px solid transparent;
  }
  @media (max-width: 320px) {
    border-left: 160px solid transparent;
  }
`

const Footer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: black;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-image: url(${footerImg});
  background-size: cover;
  background-position: center;
`

const FooterContent = styled.div`
  max-width: 1280px;
  border-left: 1px solid ${({ theme }) => theme.primaryColor};
  margin-top: 5rem;
  width: 60%;
  padding-left: 2rem;
`

const Mesh = styled.img`
  position: absolute;
  filter: invert(1);
  height: 20rem;
  top: -3rem;
  right: -7rem;
`


export default () => {
  const { t } = useTranslation()

  return (
    <Footer>
      <Mesh src='/images/footer-mesh.svg' />
      <RightTriangle/>
      <LeftTriangle/>
      <FooterContent>
        <StyledH3 as='div'>{t('home.solutions.footer.title')}</StyledH3>
        <StyledP>
          <b>{t('home.solutions.footer.subtitle')} </b>
          {t('home.solutions.footer.description')}
        </StyledP>
      </FooterContent>
    </Footer>
  )
}
