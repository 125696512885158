import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { theme as DesignSystemTheme } from './components/design-system/Theme'

const theme = {
  ...DesignSystemTheme,
  primaryColor: '#92de42',
  primaryHover: '#7dc82e',
  gray2: '#e5e5e5',
  gray3: '#999999',
  gray4: '#595959',
  blue5: '#50c5f3',
  blue4: '#7ccef4',
  blue3: '#b4e3f9',
  blue2: '#ecf8fd',
  navbar: {
    ...DesignSystemTheme.navbar,
    link: {
      ...DesignSystemTheme.navbar.link,
      activeColor: '#7dc82d',
      hoverColor: '#7dc82d',
      color: '#ffffff'
    }
  }
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Blinker;
    font-weight: 300;
    src: url("/fonts/blinker/Blinker-Light.ttf");
  }
  @font-face {
    font-family: Blinker;
    font-weight: 500;
    src: url("/fonts/blinker/Blinker-SemiBold.ttf");
  }
  @font-face {
    font-family: Blinker;
    font-weight: 500;
    src: url("/fonts/blinker/Blinker-Bold.ttf");
  }
  @font-face {
    font-family: Arimo;
    font-weight: 400;
    src: url("/fonts/arimo/Arimo-Regular.ttf");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 400;
    src: url("/fonts/roboto/Roboto-Regular.ttf");
  }
  @font-face {
    font-family: Roboto;
    font-weight: 500;
    src: url("/fonts/roboto/Roboto-Bold.ttf");
  }
  @font-face {
    font-family: Saira;
    font-weight: 200;
    src: url("/fonts/saira/Saira-Light.ttf");
  }
  @font-face {
    font-family: Saira;
    font-weight: 400;
    src: url("/fonts/saira/Saira-Regular.ttf");
  }
  :root, body {
    height: 100%;
  }
  body {
    font-size: 0.9375rem;
    line-height: 1.6;
    font-weight: 400;
    font-family: Roboto, "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #222;
  }
  #app {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

export {
  theme,
  ThemeProvider,
  GlobalStyle
}
