const offers = {
  'intro': {
    'title': 'NeurOCR is available in two operating modes and different licenses',
    'modes': [
      {
        'title': 'Interface mode',
        'subtitle': 'NeurOCR "Interface" allows you to use the solution via a web interface. The basic features are the following:',
        'description': [
          '- OCR processing of documents',
          '- Documents viewing by page on the interface',
          '- Manual selection of non-automatically OCRed areas in pages',
          '- Manual correction of contents',
          '- Creation of export profiles',
          '- Export in various formats including XML, CSV, JSON and TXT',
          '- Manual assignment of documents to export profiles',
          '- Recognition and automatic mass assignment of export profiles per document frame',
        ],
      },
      {
        'title': 'API mode',
        'subtitle': 'NeurOCR "API" allows you to use the core technology in your own programs or interfaces (EDM, database connectors, etc.)',
        'description': [
          '- OCR processing of documents',
          '- Retrieval of information from the OCR processing in JSON format (by default, other formats available on request) including recognized texts, coordinates of contents in the pages and recognition percentages',
        ],
      },
    ]
  },
  'offers': {
    'title': 'Licenses',
    'subtitle': '3 offers adapted to your needs and volumes:',
    'licenses': {
      'saas-volume': {
        'title': 'SaaS Volume',
        'cost': 'Cost per page processed according to its format and volume',
        'standard-results': 'Standard results',
        'results': '95% average OCR process quality on non-handwritten documents',
        'options': 'Automatic page rotation in the reading direction and scanning of your documents',
        'immediate': 'Immediate',
      },
      'saas-project': {
        'title': 'SaaS Project',
        'cost': 'Cost per page processed according to its format and volume',
      },
      'on-premise': {
        'title': 'On Premise',
        'cost': 'Monthly rental of NeuroComputer + Cost per page according to its format and volume',
        'cost-page': 'Monthly rental of NeuroComputer + Cost per page according to its format and volume',
        'host': 'NeuroComputer installed on site',
      },
      'interface-mode': 'Interface mode',
      'api-mode': 'API mode',
      'mensual-sub': 'Monthly subscription + Cost per page according to its format and volume',
      'guaranteed-results': 'Guaranteed results',
      'available-options': 'Options available',
      'all-options': 'All options',
      'host': 'Hosting',
      'distant-opening': 'Remote opening',
      'providing': 'Availability (excluding "scan" option)',
      'under-nth-days': 'Within {{nb}} days',
    },
  },
  'details': {
    'details': {
      'title': 'Details of the offers',
      'saas-offer1': 'The SaaS Volume offer',
      'saas-offer2': 'corresponds to projects that do not require special engineering.',
      'other-offers1': 'Saas Project and On Premise offers',
      'other-offers2': 'meet more complex issues requiring engineering, training of AI with business and project data, on-site data security.',
      'results1': 'On these offers, results are guaranteed:',
      'results2': 'if 99% OCR quality is required, we are committed to achieving this goal.',
      'limits1': 'NeurOCR integrates all types of formats according to the volume - up to 300 million documents',
      'limits2': 'and more according to our specific offers.',
      'communications1': 'In the context of',
      'communications2': 'the remote opening of SaaS offers,',
      'communications3': 'data are hosted on NeuroNalys secure servers. Secure SSL communications and AES encrypted data.',
    },
    'options': {
      'title': 'Available options',
      'self-learning1': 'Self-learning:',
      'self-learning2': 'thanks to the human corrections made in the interface that are automatically reused in the training processes.',
      'template-export1': 'Export template:',
      'template-export2': 'selection of areas in pages for automatic correspondence with the fields of the export profiles defined according to the type of document.',
      'template-matching1': 'Matching template:',
      'template-matching2': 'automatic recognition of a subsequence of an image. For example, a title block that can be treated differently from the rest of the document.',
      'search-engine1': 'Search engine:',
      'search-engine2': 'allowing searches by words or expressions in all the processed documents.',
      'rotation1': 'Automatic page rotation:',
      'rotation2': 'to put them in the reading direction.',
      'scan1': 'Scan of your documents:',
      'scan2': 'for a global answer to your issue.',
    },
    'offers-details': 'Détails des offres',
    'options-available': 'Options disponibles',
    'features': {
      'offers': [
        {
          'bold': 'L\'offre SaaS au volume ',
          'normal': 'correspond aux projets ne nécessitant pas d\'ingénierie particulière.',
        },
        {
          'bold': 'Les offres Saas Projet et On Premise ',
          'normal': 'permettent de répondre aux sujets plus complexes nécessitant de l\'ingénierie, des ré-entraînements de l\'IA avec les données métiers/projet voire d\'une étanchéité des données sur site.',
        },
        {
          'bold': 'Sur ces offres, les résultats sont garantis : ',
          'normal': 'si 99% de qualité d\'OCR sont nécessaires, nous nous engageons à atteindre cet objectif.',
        },
        {
          'bold': 'NeurOCR intégre tous types de formats au volume jusqu’à 300 millions de documents, ',
          'normal': 'au-delà pour nos offres spécifiques.',
        },
        {
          'bold': 'Dans le cadre de l\'ouverture à distance des offres SaaS, ',
          'normal': 'les données sont hébergées sur les serveurs sécurisés de NeuroNalys. Communications sécurisées en SSL et données chiffrées sous le protocole AES.',
        },
      ],
      'options': [
        {
          'bold': 'Auto-apprentissage : ',
          'normal': 'capitalisation sur les corrections humaines apportées dans l\'interface qui sont automatiquement ré-utilisées dans les processus d\'entraînement.',
        },
        {
          'bold': 'Templating d\'export : ',
          'normal': 'sélection de zones dans les pages pour correspondance automatique avec les champs des profils d\'export selon le type de document.',
        },
        {
          'bold': 'Template Matching : ',
          'normal': 'Reconnaissance automatique d\'une sous-séquence d\'une image. Par exemple, un cartouche qui peut être traité différemment du reste du document.',
        },
        {
          'bold': 'Moteur de recherche ',
          'normal': 'permettant des recherches par mots ou expressions dans l\'ensemble des documents traités.',
        },
        {
          'bold': 'Rotation automatique des pages ',
          'normal': 'pour les mettre dans le sens de lecture.',
        },
        {
          'bold': 'Scan de vos documents ',
          'normal': 'pour une réponse globale à votre problématique.',
        },
      ],
    },
    'contact': 'Contact us',
    'neurocomputer1': 'The OnPremise offer is hosted on the NeuroComputer dedicated to your structure. Allowing 65,000 billion operations per second, this \'super-computer\' ensures',
    'neurocomputer2': 'the speed and stability of operations as well as the sealing of data on site.',
  },
}

export default offers
