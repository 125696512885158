const contact = {
  'title': 'Contactez-nous',
  'subTitle': 'Nous sommes à l’écoute de vos questions et de vos demandes d’informations',
  'byPhone': 'Par téléphone',
  'byEmail': 'Par email',
  'form': 'Via ce formulaire',
  'formSuccess': 'Merci, votre demande a été envoyée. À très vite.',
  'goHome': 'Retour à l\'accueil',
  'lastname': 'Nom',
  'firstname': 'Prénom',
  'company': 'Société',
  'industry': {
    'placeholder': 'Secteur d\'activité',
    'architecture': 'Industries, Bureaux d’études, Architectes',
    'administration': 'Administrations publiques, Archives nationales, Généalogistes',
    'bank': 'Banques, Assurances, Cabinets juridiques, Experts comptables, Offices notariaux, Huissiers',
    'unknown': 'Autre',
  },
  'phone': 'Téléphone',
  'yourMessage': 'Votre message',
  'invalid-format': 'Format non valide',
  'required': 'Requis',
  'legal': 'En soumettant ce formulaire, j’accepte que les informations saisies soient traitées pour permettre de me recontacter dans le cadre d’une relation commerciale.',
  'send': 'Envoyer',
  'errors': {
    'lastname': 'Nom requis',
    'firstname': 'Prénom requis',
    'email': 'Email requis',
    'email-format': 'Email format incorrect',
    'message': 'Message requis',
    'legal': 'Accord légal requis',
    'captcha': 'Captcha requis',
  },
  'error': 'Une erreur est survenue, réessayez plus tard ou contactez un administrateur via ce',
  'error-link': 'formulaire de contact'
}

export default contact
