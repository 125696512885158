import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { A, Link } from '../components/design-system/CTAs'
import { H4, H5, P } from '../components/design-system/Typography'
import { Icon, AngleRightIcon } from '../components/design-system/Icons'
import { Container, Columns } from '../components/design-system/Grid'
import { Img } from '../components/design-system/Media'

const StyledFooter = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.primaryColor};
`

const ImgMesh = styled(Img)`
  height: 176px;
  width: 220px;
  @media (max-width: 768px) {
    position: absolute;
    bottom: 20%;
    right: 0%;
  }
`

const ImgLogo = styled(Img)`
  width: 183px;
  margin-bottom: 1.5rem;
  @media (min-width: 768px) {
    width: 220px;
  }
`

const PaddedContainer = styled(Container)`
  padding: 1.5rem 1.875rem 1.25rem;
  @media (min-width: 768px) {
    padding: 2rem 0;
  }
`

const MargedH4 = styled(H4)`
  color: ${({ theme }) => theme.white};
  margin-bottom: .75rem;
`

const MargedH5 = styled(H5)`
  color: ${({ theme }) => theme.white};
  margin-bottom: .75rem;
`

const MargedP = styled(P)`
  margin-bottom: 1rem;
`

const Ul = styled.ul`
  list-style-type: none;
  padding: 1.5rem 1rem 0 0;
  margin: 0;
  @media (min-width: 1024px) {
    padding-top: 0;
  }
`

const Li = styled.li`
  position: relative;
  margin-bottom: ${({ extraMargin }) => extraMargin ? '1.5' : '.5'}rem;
  padding: 0 0 0 1.5rem;
  color: ${({ theme }) => theme.black};
  font-family: Saira;

  ${Icon} {
    position: absolute;
    top: .125rem;
    left: 0;
    color: ${({ theme }) => theme.white};
    width: 1.125rem;
    height: 1.125rem;
  }
  > span {
    display: block;
    margin-bottom: .5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${A}, ${Link} {
    color: ${({ theme }) => theme.black};
  }
`

Li.propTypes = {
  extraMargin: PropTypes.bool,
  theme: PropTypes.shape({
    black: PropTypes.string,
    white: PropTypes.string,
  }),
}

Li.defaultProps = {
  extraMargin: false,
}

const Copyright = styled.div`
  display: flex;
  padding: 1rem;
  background-color: ${({ theme }) => theme.black};

  ${P} {
    color: ${({ theme }) => theme.white};
    text-align: center;
    line-height: 1.5rem;
    flex-grow: 1;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 1rem;
  }
`

Copyright.propTypes = {
  theme: PropTypes.shape({
    black: PropTypes.string,
    white: PropTypes.string,
  }),
}

const Footer = ({ app }) => {
  const { t } = useTranslation()

  const appName = app === 'neurocop' ? 'NeuroCop' : 'NeurOCR'

  return (
    <StyledFooter>
      <PaddedContainer>
        <Columns nb={3.25}>
          <ImgLogo src='/images/footer-logo.svg' alt='logo' />
          <ImgMesh src='/images/footer-mesh.svg' alt='mesh' />
        </Columns>
        <Columns nb={6}>
          <MargedH4>
            {t('footer.title', { app: appName })}</MargedH4>
          <MargedH5>{t('footer.subtitle')}</MargedH5>
          <MargedP>{t('footer.description.0')}</MargedP>
          <MargedP>{t('footer.description.1')}</MargedP>
        </Columns>
        <Columns nb={2.75}>
          <Ul>
            <Li extraMargin>
              <AngleRightIcon />
              <span>NEURONALYS</span>
              <span>
                104 Rue Nationale<br />
                59800 Lille<br />
                FRANCE<br />
              </span>
              <P as='a' href='tel:+33374096664'></P>
              <A href='tel:+33374096664'>
                +33.3.74.09.66.64
              </A>
            </Li>
            <Li>
              <AngleRightIcon />
              <A href='https://neuronalys.fr/' target='_blank'>Neuronalys.ai</A>
            </Li>
            <Li extraMargin>
              <AngleRightIcon />
              <A href='https://neuronalys.ai/#contact'>
                contact@neuronalys.ai
              </A>
            </Li>
            <Li>
              <AngleRightIcon />
              <Link to='/legal-notices'>{t('footer.legal-notices')}</Link>
            </Li>
          </Ul>
        </Columns>
      </PaddedContainer>
      <Copyright>
        <P>{t('footer.copyright')}</P>
        <P>{t('footer.copyright-app', { app: appName })}</P>
      </Copyright>
    </StyledFooter>
  )
}


Footer.propTypes = {
  app: PropTypes.string,
}

Footer.defaultProps = {
  app: 'neurocr',
}

export default Footer
