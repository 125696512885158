import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import neurOCRLogo from '../../../../../public/images/neurOCR-logo-only.svg'
import innovationsImg from '../../../../../public/images/img_innovations.png'

import {
  Section,
  P,
  H2,
  NavButton,
  AngleRightIcon,
} from '../../components'

const InnovationsSection = styled(Section)`
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: ${({ theme }) => theme.white};
  padding-top: 3rem;
  max-width: 1280px;

  @media (max-width: 1024px) {
    text-align: start;
    padding: 1rem 2rem;
  }
`

const NeurocrIcon = styled.img`
  width: 128px;
  margin: auto;
`

const InnovationsH2 = styled(H2)`
  text-transform: uppercase;
  text-align: center;
  margin-block-end: 2rem;
  font-family: Blinker;
  color: #000;
  text-align: center;
  width: 80%;
  margin: auto;
  margin-top: 3rem;

  @media (max-width: 1024px) {
    width: 100%;
    font-size: 1.5rem;
  }
`

const StyledP = styled(P)`
  margin: O;
  margin-bottom: 1rem;
  color: #000;
`

const Grid = styled.div`
  padding: 0 6rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1024px) {
    padding: 0;
    flex-direction: column;
    justify-content: center;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    margin-top: 0;
    flex-direction: column;
  }
`

const InnovationsImgContainer = styled.div`
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  display: flex;
  justify-content: flex-end;
  margin-top: -2rem;

  img {
    max-height: 33rem;
    margin-right: 2rem;
  }

  @media (max-width: 1024px) {
    margin-top: 0;
    grid-column-start: auto;
    grid-row-start: 2;
    grid-row-end: auto;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }
`

const Mesh = styled.img`
  height: 20rem;
  position: absolute;
  top: 0;
  left: -11rem;
  opacity: 0.2;
`

export default () => {
  const { t } = useTranslation()

  return (
    <InnovationsSection>
      <Mesh src="/images/footer-mesh.svg" alt="mesh" />
      <NeurocrIcon src={neurOCRLogo} />
      <InnovationsH2>{t('innovations.intro.title')}</InnovationsH2>
      <Grid>
        <Flex>
          <InnovationsImgContainer>
            <img src={innovationsImg} />
          </InnovationsImgContainer>
          <div>
            {
              t('innovations.intro.description', { returnObjects: true }).map((line, lineIndex) => {
                const allBoldSentences = [
                  /* FR */
                  'L\'innovation est au cœur de notre stratégie d\'entreprise, elle se caractérise par des investissements massifs et constants en R&D ainsi qu\'une volonté d\'excellence de la part de nos ingénieurs.',
                  'Cette approche nous a permis de disrupter le marché de la reconnaissance optique de caractères (OCR), pour le moins standardisé. Effectivement, NeurOCR comporte une couche logicielle d\'analyse sémantique basée sur l\'Intelligence Artificielle, lui permettant de comprendre différents langages métiers et ce, quel que soit le secteur d\'activité concerné !',
                  // 'NeurOCR bouscule les codes et rend possible la reconnaissance de caractères dans les formats vidéo',
                  /* EN */
                  'Innovation is at the heart of our corporate strategy; it is characterized by massive and constant investments in R&D and engineers who strive for excellence.',
                  'This approach has allowed us to disrupt the optical character recognition (OCR) market, which was standardized until now. Indeed, NeurOCR includes a semantic analysis software layer based on Artificial Intelligence, allowing it to understand different business languages, in any industry!',
                  // 'NeurOCR breaks the rules and makes it possible to recognize characters in video formats',
                ]

                let foundBoldSentence = null
                foundBoldSentence = allBoldSentences.find(boldSentence => line.indexOf(boldSentence) !== -1)

                if (foundBoldSentence !== null) {
                  const splittedLine = line.split(foundBoldSentence)

                  return (
                    <StyledP key={lineIndex}>
                      {splittedLine[0]}
                      <b>{foundBoldSentence}</b>
                      {splittedLine[1]}
                    </StyledP>
                  )
                }

                return (
                  <StyledP key={lineIndex}>{line}</StyledP>
                )
              })
            }
          </div>
        </Flex>
      </Grid>
    </InnovationsSection>
  )
}
