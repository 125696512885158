import React, { Fragment, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  H1,
  H4,
  P,
  Container,
  Section,
} from '../components'

import neurOCRLogo from '../../../../public/images/neurOCR-logo-only.svg'

const StyledContainer = styled.div`
  position: relative;
  margin-bottom: 5rem;
  overflow: hidden;
`

const StyledSection = styled(Section)`
  display: flex;
  flex-direction: column;
`

const Mesh = styled.img`
  position: absolute;
  left: -5%;
  top: -5%;
  opacity: 0.2;
  width: 32rem;

  @media (max-width: 992px) {
    top: auto;
  }
`

const NeurocrIcon = styled.img`
  width: 10rem;
  margin: auto;
  align-self: center;
  margin-top: 2rem;

  @media (max-width: 1024px) {
    width: 9rem;
  }
`

const ContactH1 = styled(H1)`
  font-family: Saira;
  font-weight: 300;
  margin: 1rem;
  margin-bottom: 4rem;
  text-transform : uppercase;
  font-size: 2.25rem;
`

const CategoryTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: Blinker;
  font-size: 1.9rem;
  column-gap: .9rem;
  margin: 2.7rem 0;
  line-height: 1.5rem;
`

const StyledH4 = styled(H4)`
  margin: 1rem 0;
  margin-top: 2rem;
`

const StyledP = styled(P)`
  margin-top: 0.875rem;
`


export default () => {
  const { t } = useTranslation()

  useEffect(() => window.scrollTo(0, 0), [])

  return (
    <StyledContainer>
      <Mesh src='/images/mesh-black.png' />
      <Container>
        <StyledSection>
          <NeurocrIcon src={neurOCRLogo} />
          <ContactH1>{t('terms.intro.title')}</ContactH1>
          {t('terms.intro.description', { returnObjects: true }).map((line, lineIndex) => {
            if (line.split('www.neurocop.ai').length === 2) {
              return (
                <Fragment key={`terms-intro-${lineIndex}`}>
                  {line.split('www.neurocop.ai')[0]}
                  <a href='www.neurocop.ai'>www.neurocop.ai</a>
                  {line.split('www.neurocop.ai')[1]}
                </Fragment>
              )
            } else {
              return (
                <StyledP key={`terms-intro-${lineIndex}`}>
                  {line}
                </StyledP>
              )
            }
          })}
          {t('terms.content.categories', { returnObjects: true }).map((category, categoryIndex) => (
            <div key={`terms-${categoryIndex}`}>
              <CategoryTitle>{category.title}</CategoryTitle>
              {category.sections.map((section, sectionIndex) => (
                <div key={`terms-${categoryIndex}-${sectionIndex}`}>
                  <StyledH4>{section.title}</StyledH4>
                  {section.content.map((content, contentIndex) => (
                    <StyledP key={`terms-${categoryIndex}-${sectionIndex}-${contentIndex}`}>
                      {content}
                    </StyledP>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </StyledSection>
      </Container>
    </StyledContainer>
  )
}
