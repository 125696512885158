const home = {
  'intro': {
    'title': 'L’OCR intelligent qui comprend en plus de lire !',
    'subtitle': 'NeurOCR est la solution d’Intelligence Artificielle de reconnaissance de caractères (OCR : Optical Character Recognition) pour la gestion des ressources documentaires, aussi complexes soient-elles.',
    'description': [
      'Son objectif : rendre ces ressources accessibles (indexation et restitution), utilisables (rationalisation et analyse) et pérennes (amélioration et enrichissement).',
      'NeurOCR permet également de répondre aux besoins de récupération de contenus textuels pour les groupes industriels, PME, ETI et acteurs publics.',
    ]
  },
  'content': {
    'title': 'Secteurs d\'activité',
    'features': [
      {
        'title': 'Industries, Bureaux d’études, Architectes',
        'description': [
          'Facilitez le traitement de chaque dossier en indexant les différentes pièces de façon rationnelle et éditable.',
          'Gagnez de l’espace physique en supprimant vos rayonnages une fois toutes vos archives océrisées.',
        ]
      },
      {
        'title': 'Administrations publiques, Archives nationales, Généalogistes',
        'description': [
          'Convertissez les documents manuscrits dans un format éditable.',
          'Traitez avec efficacité les documents mal scannés et/ou détériorés.',
        ],
      },
      {
        'title': 'Banques, Assurances, Cabinets juridiques, Experts comptables, Offices notariaux, Huissiers',
        'description': [
          'Isolez et comparez les références similaires sur l’ensemble de vos plans et schémas techniques.',
          'Océrisez un très grand volume de documents pouvant aller jusqu’à 300 millions (voire au-delà pour nos offres spécifiques).',
        ],
      },
    ],
  },
  'solutions': {
    'title': 'De la reconnaissance optique à la reconnaissance intelligente de caractères',
    'icon-descriptions': [
      {
        'title': 'Acquisition des données depuis tous types de documents :',
        'description': 'Normés, standardisés, manuscrits, dactylographiés ou complexes, l\'IA de NeurOCR traite tous types de caractères dans une image (chiffres, lettres, etc.), même mal scannés ou partiellement détériorés par le temps.',
      },
      {
        'title': 'Acquisition des données depuis tous types de supports :',
        'description': 'Formats standards, formats hors normes, PDF, images, plans, etc., l\'IA de NeurOCR traite tous types de formats et permet la reconstruction des références métiers.',
      },
      {
        'title': 'Reconnaissance des caractères dans toutes les orientations :',
        'description': 'L\'IA de NeurOCR est spécialement entraînée via un jeu de rotations, redimensionnements, ajout de bruit et variations de netteté des caractères. ',
      },
      {
        'title': 'Affectation des profils d\'export :',
        'description': 'Via une sélection de zones dans les pages, la solution assure une correspondance automatique avec les champs des profils d\'export définis selon le type de document.',
      },
      {
        'title': 'Restitution sous tous types de formats :',
        'description': 'Une fois l\'océrisation effectuée, l\'interface permet la création de profils d’export avec affectation automatique par trame de documents. L’export est proposé sous différents formats dont XLS, DOC, PDF, XML, CSV, JSON et TXT.',
      },
      {
        'title': 'Qualité d\'océrisation garantie :',
        'description': 'NeurOCR offre des résultats sans égal grâce à son IA basée sur des réseaux neuronaux profonds et des algorithmes auto-apprenants garantissant des performances optimales.',
      },
    ],
    'button-features': 'Voir le détail des fonctionnalités',
    'button-offers': 'Consultez nos offres',
    'footer': {
      'title': 'Disruptivité !',
      'subtitle': 'NeurOCR traite en temps réel les formats vidéos !',
      'description': 'La solution s\'intègre aux systèmes embarqués pour l\'océrisation à la volée des textes dans tout type de situation !'
    }
  },
}

export default home
