import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  Section,
  H2,
  H3,
  P,
  Container,
  AngleRightIcon,
  NavButton,
} from '../../components'

import image1 from '../../../../../public/images/homepage/acquisition-documents.svg'
import image2 from '../../../../../public/images/homepage/acquisition-supports.svg'
import image3 from '../../../../../public/images/homepage/adaptabiliteHome.svg'
import image4 from '../../../../../public/images/homepage/export-profile.svg'
import image5 from '../../../../../public/images/homepage/all-format.svg'
import image6 from '../../../../../public/images/homepage/ocerize-quality.svg'

const StyledSection = styled(Section)`
  padding: 1rem 0;
  text-align: center;
  margin-bottom: 2rem;
  padding-top: 3rem;
`

const Title = styled(H2)`
  font-family: Blinker;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-top: 2rem;
  margin-bottom: 4rem;
  font-size: 1.875rem;
  font-weight: 300;
  width: 80%;
  margin: auto;

  @media (max-width: 1024px) {
    font-size: 1.4rem !important;
    letter-spacing: 0.2em;
  }
`

const StyledH3 = styled(H3)`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  line-height: 1.5rem;

  grid-column-start: ${({ order }) => {
    if (order < 3) return order + 1
    if (order === 3) return 0
    return order - 2
  }};
  grid-row-start: ${({ order }) => {
    if (order < 3) return 1
    return 2
  }};
`

const Grid = styled(Container)`
  margin: 2rem auto;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.primaryColor};

  & > div:nth-child(3n + 1) {
    margin-right: 1.5rem;
  }
  & > div:nth-child(3n + 2) {
    margin: 0 1.5rem;
  }
  & > div:nth-child(3n + 3) {
    margin-left: 1.5rem;
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;

    &&& > div {
      margin: 0;
    }
    &&&&&& > div:not(:first-child) {
      margin-top: 2rem;
    }
  }
`

const StyledP = styled(P)`
  font-family: Saira;
  font-size: 0.875rem;

  @media (max-width: 1024px) {
    grid-row-start: ${({ order }) => order * 3};
  }
`

const StyledImg = styled.img`
  margin: auto;
  height: 8rem;
  width: auto;

  grid-column-start: ${({ order }) => {
    if (order < 3) return order + 1
    if (order === 3) return 0
    return order - 2
  }};
  grid-row-start: ${({ order }) => {
    if (order < 3) return 1
    return 2
  }};


  @media (max-width: 1024px) {
    grid-row-start: ${({ order }) => order === 1 ? 1 : (order * 3) - 2};
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ImgContainer = styled.div`
  height: 10rem;
  display: flex;
`

const StyledNavButton = styled(NavButton)`
  grid-column-start: 2;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 2rem;
  width: max-content;
  white-space: normal;
  width: auto;

  @media (max-width: 1024px) {
    grid-column-start: auto;
  }
`

export default () => {
  const { t } = useTranslation()

  const images = [ image1, image2, image3, image4, image5, image6 ]

  return (
    <>
      <StyledSection>
        <Title>{t('home.solutions.title')}</Title>
        <Grid>
          {
            images.map((image, i) => (
              <InfoContainer key={`home-solutions-${i}`}>
                <ImgContainer>
                  <StyledImg order={i} src={image} />
                </ImgContainer>
                <StyledH3 order={i} as='div'>
                  {t(`home.solutions.icon-descriptions.${i}.title`)}
                </StyledH3>
                <StyledP order={4}>
                  {t(`home.solutions.icon-descriptions.${i}.description`)}
                </StyledP>
              </InfoContainer>
            ))
          }
        <StyledNavButton rightIcon={<AngleRightIcon />} primary to='/features'>
          {t('home.solutions.button-features')}
        </StyledNavButton>
        </Grid>
      </StyledSection>
    </>
  )
}
