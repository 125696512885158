import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  Section,
  H2,
  H3,
  CheckIcon,
  NavButton,
  AngleRightIcon,
  Container,
} from '../../components'

import image3 from '../../../../../public/images/homepage/SA1.png'
import image2 from '../../../../../public/images/homepage/SA2.png'
import image1 from '../../../../../public/images/homepage/SA3.png'

const StyledSection = styled(Section)`
  padding: 1rem 0;
  text-align: center;
`

const Title = styled(H2)`
  font-family: Blinker;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-top: 2rem;
  margin-bottom: 4rem;
  font-size: 1.875rem;
  font-weight: 300;

  @media (min-width: 960px) {
    letter-spacing: 0.2em;
  }
`

const StyledH3 = styled(H3)`
  font-size: 1.2rem;
  line-height: 1.5rem;

  @media (max-width: 1024px) {
    grid-row-start: ${({ order }) => (order * 3) - 1};
  }
`

const Grid = styled(Container)`
  margin: 2rem auto;
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 1fr 1fr 1fr;

  & > div:nth-child(2n + 1) {
    margin-right: 1.5rem;
  }
  & > div:nth-child(2n + 2) {
    margin: 0 1.5rem;
  }
  & > div:nth-child(2n + 3) {
    margin-left: 1.5rem;
  }

  img {
    margin: auto;
    margin-bottom: 2rem;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    width: 100%;
  }
`

const StyledImg = styled.img`
  @media (max-width: 1024px) {
    grid-row-start: ${({ order }) => (order * 3) - 2};
  }
`

const StyledUl = styled.ul`
  list-style: none;
  padding: 0;

  @media (max-width: 1024px) {
    grid-row-start: ${({ order }) => order * 3};
  }

  @media (min-width: 960px) {
    flex: 1;
  }
  `

const Li = styled.li`
  margin-bottom: 1.5rem;
  text-align: start;
  position: relative;
`

const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  top: 4px;
  color: #23DF21;
`

const P = styled.p`
  padding-left: 1.25rem;
  font-family: Saira;
  font-size: 0.875rem;
`

const StyledNavButton = styled(NavButton)`
  grid-column-start: 2;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 0;
  white-space: normal;
  width: auto;

  @media (max-width: 1024px) {
    grid-column-start: auto !important;
  }
`

export default () => {
  const { t } = useTranslation()

  const images = [ image1, image2, image3 ]

  return (
    <StyledSection>
      <Title>{t('home.content.title')}</Title>
      <Grid>
        {
          images.map((image, i) => (
            <div key={`home-content-${i}`}>
              <StyledImg order={i} src={image} />
              <StyledH3 order={i} as='div'>
                {t(`home.content.features.${i}.title`)}
              </StyledH3>
              <StyledUl order={i} as='div'>
                {
                  t(`home.content.features.${i}.description`, { returnObjects: true }).map((text, ii) => (
                    <Li key={`home-intro-description-${ii}`}>
                      <StyledCheckIcon />
                      <P>{text}</P>
                    </Li>
                  ))
                }
              </StyledUl>
            </div>
          ))
        }
        <StyledNavButton
          rightIcon={<AngleRightIcon />}
          primary to='/offers'
          style={{ gridColumnStart: 2, marginBottom: '1.5rem' }}
        >
          {t('home.solutions.button-offers')}
        </StyledNavButton>
      </Grid>
    </StyledSection>
  )
}
