import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// French
import fr_contact from './fr/contact'
import fr_home from './fr/home'
import fr_innovations from './fr/innovations'
import fr_footer from './fr/footer'
import fr_offers from './fr/offers'
import fr_header from './fr/header'
import fr_terms from './fr/terms'
import fr_features from './fr/features'

// English
import en_contact from './en/contact'
import en_home from './en/home'
import en_innovations from './en/innovations'
import en_footer from './en/footer'
import en_offers from './en/offers'
import en_header from './en/header'
import en_terms from './en/terms'
import en_features from './en/features'

const hostname = window.location.hostname
const tld = (
  hostname !== 'localhost' && hostname.split('.').length === 2
    ? hostname.split('.')[1]
    : null
  )

const resources = {
  fr: {
    translation: {
      home: { ...fr_home },
      contact: { ...fr_contact },
      innovations: { ...fr_innovations },
      footer: { ...fr_footer },
      offers: { ...fr_offers },
      header: { ...fr_header },
      terms: { ...fr_terms },
      features: { ...fr_features },
    },
  },
  en: {
    translation: {
      home: { ...en_home },
      contact: { ...en_contact },
      innovations: { ...en_innovations },
      footer: { ...en_footer },
      offers: { ...en_offers },
      header: { ...en_header },
      terms: { ...en_terms },
      features: { ...en_features },
    },
  },
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: !tld || (tld === 'ai' || tld === 'fr') ? 'fr' : 'en',
    fallbackLng: 'fr'
  })

export default i18n;
