const offers = {
  'intro': {
    'title': 'NeurOCR est proposé selon deux modes de fonctionnement et différentes licences',
    'modes': [
      {
        'title': 'Mode Interface',
        'subtitle': 'NeurOCR "Interface" vous permet d\'exploiter la solution via une interface web. Les fonctionnalités de base sont les suivantes :',
        'description': [
          '- Océrisation des documents',
          '- Visualisation des documents par page sur l\'interface',
          '- Sélection manuelle des zones non automatiquement océrisées dans les pages',
          '- Correction manuelle des contenus',
          '- Création de profils d\'export',
          '- Export sous différents formats dont XML, CSV, JSON et TXT',
          '- Affectation manuelle des documents aux profils d\'export',
          '- Reconnaissance et affectation automatique en masse des profils d\'export par trame de documents',
        ],
      },
      {
        'title': 'Mode API',
        'subtitle': 'NeurOCR "API" permet d\'utiliser le coeur de la technologie dans vos propres programmes ou interfaces (GED, connecteurs bases de données, etc.)',
        'description': [
          '- Océrisation des documents',
          '- Récupération des informations issues de la phase d\'OCR au format JSON (par défaut, autres formats disponibles sur demande) comprenant les textes reconnus, les coordonnées des contenus dans les pages et les pourcentages de reconnaissance',
        ],
      },
    ]
  },
  'offers': {
    'title': 'Licenses',
    'subtitle': '3 offres adaptées à vos besoins et volumes :',
    'licenses': {
      'saas-volume': {
        'title': 'SaaS Volume',
        'cost': 'Coût par page traitée selon son format et volume',
        'standard-results': 'Résultats standards',
        'results': 'Moyenne de 95 % de qualité d\'océrisation sur documents non-manuscrits',
        'options': 'Rotation automatique des pages dans le sens de lecture et scan de vos documents',
        'immediate': 'Immédiate',
      },
      'saas-project': {
        'title': 'SaaS Projet',
        'cost': 'Coût par page traitée selon son format et volume',
      },
      'on-premise': {
        'title': 'On Premise',
        'cost': 'Location mensuelle du NeuroComputer + Coût par page selon son format et volume',
        'cost-page': 'Location mensuelle du NeuroComputer + Coût par page traitée selon son format et volume',
        'host': 'NeuroComputer déployé sur site',
      },
      'interface-mode': 'Mode Interface',
      'api-mode': 'Mode API',
      'mensual-sub': 'Abonnement mensuel + Coût par page selon son format et volume',
      'guaranteed-results': 'Résultats garantis',
      'available-options': 'Options disponibles',
      'all-options': 'Toutes les options',
      'host': 'Hébergement',
      'distant-opening': 'Ouverture à distance',
      'providing': 'Mise à disposition (hors option "scan")',
      'under-nth-days': 'Sous {{nb}} jours',
    },
  },
  'details': {
    'details': {
      'title': 'Détails des offres',
      'saas-offer1': 'L\'offre SaaS Volume',
      'saas-offer2': 'correspond aux projets ne nécessitant pas d\'ingénierie particulière.',
      'other-offers1': 'Les offres Saas Projet et On Premise',
      'other-offers2': 'permettent de répondre aux sujets plus complexes nécessitant de l\'ingénierie, des ré-entraînements de l\'IA avec les données métiers et projet, voire d\'une étanchéité des données sur site.',
      'results1': 'Sur ces offres, les résultats sont garantis :',
      'results2': 'si 99 % de qualité d\'OCR sont nécessaires, nous nous engageons à atteindre cet objectif.',
      'limits1': 'NeurOCR intégre tous types de formats au volume jusqu’à 300 millions de documents,',
      'limits2': 'voire au-delà pour nos offres spécifiques.',
      'communications1': 'Dans le cadre de',
      'communications2': 'l\'ouverture à distance des offres SaaS, ',
      'communications3': 'les données sont hébergées sur les serveurs sécurisés de NeuroNalys. Communications sécurisées en SSL et données chiffrées sous le protocole AES.',
    },
    'options': {
      'title': 'Options disponibles',
      'self-learning1': 'Auto-apprentissage :',
      'self-learning2': 'capitalisation sur les corrections humaines apportées dans l\'interface qui sont automatiquement ré-utilisées dans les processus d\'entraînement.',
      'template-export1': 'Modèle d\'export : ',
      'template-export2': 'sélection de zones dans les pages pour correspondance automatique avec les champs des profils d\'export selon le type de document.',
      'template-matching1': 'Modèle de correspondance :',
      'template-matching2': 'reconnaissance automatique d\'une sous-séquence d\'une image. Par exemple, un cartouche qui peut être traité différemment du reste du document.',
      'search-engine1': 'Moteur de recherche :',
      'search-engine2': 'permettant des recherches par mots ou expressions dans l\'ensemble des documents traités.',
      'rotation1': 'Rotation automatique des pages :',
      'rotation2': 'pour les mettre dans le sens de lecture.',
      'scan1': 'Scan de vos documents :',
      'scan2': 'pour une réponse globale à votre problématique.',
    },
    'offers-details': 'Détails des offres',
    'options-available': 'Options disponibles',
    'features': {
      'offers': [
        {
          'bold': 'L\'offre SaaS au volume ',
          'normal': ' correspond aux projets ne nécessitant pas d\'ingénierie particulière.',
        },
        {
          'bold': 'Les offres Saas Projet et On Premise ',
          'normal': 'permettent de répondre aux sujets plus complexes nécessitant de l\'ingénierie, des ré-entraînements de l\'IA avec les données métiers/projet voire d\'une étanchéité des données sur site.',
        },
        {
          'bold': 'Sur ces offres, les résultats sont garantis : ',
          'normal': 'si 99% de qualité d\'OCR sont nécessaires, nous nous engageons à atteindre cet objectif.',
        },
        {
          'bold': 'NeurOCR intégre tous types de formats au volume jusqu’à 300 millions de documents, ',
          'normal': 'au-delà pour nos offres spécifiques.',
        },
        {
          'bold': 'Dans le cadre de l\'ouverture à distance des offres SaaS, ',
          'normal': 'les données sont hébergées sur les serveurs sécurisés de NeuroNalys. Communications sécurisées en SSL et données chiffrées sous le protocole AES.',
        },
      ],
      'options': [
        {
          'bold': 'Auto-apprentissage : ',
          'normal': 'capitalisation sur les corrections humaines apportées dans l\'interface qui sont automatiquement ré-utilisées dans les processus d\'entraînement.',
        },
        {
          'bold': 'Templating d\'export : ',
          'normal': 'sélection de zones dans les pages pour correspondance automatique avec les champs des profils d\'export selon le type de document.',
        },
        {
          'bold': 'Template Matching : ',
          'normal': 'Reconnaissance automatique d\'une sous-séquence d\'une image. Par exemple, un cartouche qui peut être traité différemment du reste du document.',
        },
        {
          'bold': 'Moteur de recherche ',
          'normal': 'permettant des recherches par mots ou expressions dans l\'ensemble des documents traités.',
        },
        {
          'bold': 'Rotation automatique des pages ',
          'normal': 'pour les mettre dans le sens de lecture.',
        },
        {
          'bold': 'Scan de vos documents ',
          'normal': 'pour une réponse globale à votre problématique.',
        },
      ],
    },
    'contact': 'Contactez-nous',
    'neurocomputer1': 'L\'offre OnPremise est hébergée sur le NeuroComputer dédié à votre structure. Permettant 65 000 milliards d\'opérations par secondes, ce \'super-ordinateur\' assure',
    'neurocomputer2': 'la rapidité et la stabilité des opérations ainsi que l\'étanchéité des données sur site.',
  },
}

export default offers
