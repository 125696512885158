import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { useTranslation } from 'react-i18next'
import {
  H1,
  H2,
  H4,
  P,
  A,
  Icon,
  Link,
  NeurocopIcon,
  PhoneIcon,
  SendIcon,
  TypingIcon,
  AngleDownIcon,
  Row,
  Container,
  FormGroup,
  Form,
  TextInput,
  Select,
  Option,
  Checkbox,
  Button,
  Section,
  Columns,
  CheckCircleIcon,
  SpinnerAnimatedIcon
} from '../components'

import * as services from '../services'

const StyledContainer = styled.div`
  overflow-x: hidden;
  position: relative;
`

const ContactNeurocopIcon = styled(NeurocopIcon)`
  width: 128px;
  height: 128px;
  display: block;
  margin: 2rem auto 1.25rem;
`

const ContactH1 = styled(H1)`
  font-family: Blinker;
  font-weight: 300;
  margin: 1rem;
  text-transform : uppercase;
  font-size: 2.25rem;
`

const ContactH4 = styled(H4)`
  text-align: center;
  margin-bottom: 0.5625rem;
`

const CenteredP = styled(P)`
  text-align: center;
`

const CircleDiv = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.primaryColor};
  display: block;
  margin: auto;
  border-radius: 50%;
  margin-top: 2rem;
  margin-bottom: 0.625rem;
  position: relative;
`

const InputsContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 48%;
    justify-content: space-between;
  }
`

const ContactColumns = styled(Columns)`
  text-align: center;
`

const CircleIcon = styled.span`
  ${Icon} {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    color: ${({ theme }) => theme.white};
  }
`
const AngleDownDiv = styled.div`
  display: block;
  margin: auto;
`

const ContactAngleDownIcon = styled(AngleDownIcon)`
  text-align: center;
  color: ${({ theme }) => theme.primaryColor};
  width: 23px;
  height: 23px;
  margin-bottom: 1.125rem;
`

const StyledFormGroup = styled(FormGroup)`
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`

const ContactInput = styled(TextInput)`
  width: 100%;
`

const ContactInputMessage = styled(TextInput)`
  width: 100%;
  padding: 0.87rem;
  box-sizing: border-box;
  height: 100%;
`

const StyledSelect = styled(Select)`
  width: 100%;
  margin: auto;
  display: block;
`

const ContactButton = styled(Button)`
  display: block;
  margin: auto;
  margin: 1.25rem auto 3rem;
`

const StyledRow = styled(Row)`
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    display: flex;
  }
`

const Error = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  color: ${({ theme }) => theme.errorRed};
`

const ErrorLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.errorRed};

  &:hover {
    cursor: pointer;
  }
`

const FormGroupCaptcha = styled(FormGroup)`
  margin: 1rem;
  justify-content: center;
`

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  width: 5rem;
  height: 5rem;
  color: #92DE42;
  margin: 1rem auto;
`

const StyledH2 = styled(H2)`
  margin-bottom: 1rem;
`

const Success = styled.div`
  text-align: center;
  margin: 2rem;
`

const StyledRowInfo = styled(Row)`
  margin-top: 2rem;
`

const Mesh = styled.img`
  position: absolute;
  left: -5%;
  top: -5%;
  opacity: 0.2;
  width: 32rem;
`

const StyledCheckbox = styled(Checkbox)`
  align-items: unset;

  input {
    margin-top: 1rem;
  }
`

// Source: https://emailregex.com/
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default () => {
  const [lastname, setLastname] = useState('')
  const [firstname, setFirstname] = useState('')
  const [company, setCompany] = useState('')
  const [industry, setIndustry] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [legal, setLegal] = useState(false)

  const [onError, setOnError] = useState(false)
  const [onRequestError, setOnRequestError] = useState(null)
  const [onRequest, setOnRequest] = useState(false)
  const [sent, setSent] = useState(false)

  const [captchaToken, setCaptchaToken] = useState('')

  const { t } = useTranslation()

  useEffect(() => window.scrollTo(0, 0), [])

  const showError = () => {
    if (onError) {
      return (
        <Error>
          {(lastname.length <= 0) ? <>{t('contact.errors.lastname')}<br /></> : null}
          {(firstname.length <= 0) ? <>{t('contact.errors.firstname')}<br /></> : null}
          {(email.length <= 0) ? <>{t('contact.errors.email')}<br /></> : null}
          {!EMAIL_REGEX.test(email) ? <>{t('contact.errors.email-format')}<br /></> : null}
          {(message.length <= 0) ? <>{t('contact.errors.message')}<br /></> : null}
          {!legal ? <>{t('contact.errors.legal')}<br /></> : null}
          {captchaToken.length <= 0 ? <>{t('contact.errors.captcha')}<br /></> : null}
        </Error>
      )
    } else if (onRequestError === 500) {
      return (
        <Error>
          {t('contact.error')}
          <ErrorLink target='_blank' href='https://neuronalys.ai/contact'>
            {t('contact.error-link')}
          </ErrorLink>
        </Error>
      )
    }

    return null
  }

  const handleSubmit = e => {
    e.preventDefault()
    e.stopPropagation()

    setOnRequest(true)
    setOnError(false)
    setOnRequestError(null)

    if (
      firstname.length <= 0
      || lastname.length <= 0
      || email.length <= 0
      || !EMAIL_REGEX.test(email)
      || message.length <= 0
      || !legal
      || captchaToken.length <= 0
    ) {
      setOnRequest(false)
      setOnError(true)

      return false
    }

    services
      .createContact({
        firstname,
        lastname,
        email,
        company,
        industry,
        phone,
        message,
        legal,
        captchaToken
      }).then(() => {
        setSent(true)
        setOnRequest(false)
      }).catch(err => {
        console.error(err)

        if (err.response && err.response.status)
          setOnRequestError(err.response.status)

        setOnRequest(false)
      })
  }

  const handleVerificationSuccess = (
    captchaToken => setCaptchaToken(captchaToken)
  )

  return (
    <StyledContainer>
      <Mesh src='/images/mesh-black.png' />
        <Container>
        <Section>
          <ContactNeurocopIcon />
          <ContactH1>{t('contact.title')}</ContactH1>
          <CenteredP>{t('contact.subTitle')}</CenteredP>
          <StyledRowInfo>
            <ContactColumns nb={4}>
              <CircleDiv><CircleIcon><PhoneIcon /></CircleIcon></CircleDiv>
              <ContactH4>{t('contact.byPhone')}</ContactH4>
              <A href='tel:+33374096664'>+33.3.74.09.66.64</A>
            </ContactColumns>
            <ContactColumns nb={4}>
              <CircleDiv><CircleIcon><TypingIcon /></CircleIcon></CircleDiv>
              <ContactH4>{t('contact.form')}</ContactH4>
              <AngleDownDiv>
                <ContactAngleDownIcon />
              </AngleDownDiv>
            </ContactColumns>
            <ContactColumns nb={4}>
              <CircleDiv><CircleIcon><SendIcon /></CircleIcon></CircleDiv>
              <ContactH4>{t('contact.byEmail')}</ContactH4>
              <A target='_blank' href='mailto:contact@neurocr.ai'>contact@neurocr.ai</A>
            </ContactColumns>
          </StyledRowInfo>
        </Section>
        <Section>
          <Container>
          {
            sent ? (
              <Success>
                <StyledCheckCircleIcon />
                <StyledH2>{t('contact.formSuccess')}</StyledH2>
                <Link to='/'>{t('contact.goHome')}</Link>
              </Success>
            ) : (
              <Form onSubmit={handleSubmit}>
                <StyledRow>
                  <InputsContainer>
                    <StyledFormGroup>
                      <ContactInput
                        required
                        label={t('contact.lastname')}
                        name='lastname'
                        onChange={setLastname}
                        value={lastname}
                      />
                    </StyledFormGroup>
                    <StyledFormGroup>
                      <ContactInput
                        required
                        label={t('contact.firstname')}
                        name='firstname'
                        onChange={setFirstname}
                        value={firstname}
                        validator={v => v.trim().length === 0 ? 'Requis' : null}
                      />
                    </StyledFormGroup>
                    <StyledFormGroup>
                      <ContactInput
                        label={t('contact.company')}
                        name='company'
                        onChange={setCompany}
                        value={company}
                      />
                    </StyledFormGroup>
                    <StyledFormGroup>
                      <StyledSelect
                        gray
                        placeholder={t('contact.industry.placeholder')}
                        onChange={setIndustry}
                      >
                        <Option
                          value='bank'
                          selected={industry === 'bank'}
                        >
                          {t('contact.industry.bank')}
                        </Option>
                        <Option
                          value='industry'
                          selected={industry === 'industry'}
                        >
                          {t('contact.industry.industry')}
                        </Option>
                        <Option
                          value='administration'
                          selected={industry === 'administration'}
                        >
                          {t('contact.industry.administration')}
                        </Option>
                        <Option
                          value='architecture'
                          selected={industry === 'architecture'}
                        >
                          {t('contact.industry.architecture')}
                        </Option>
                        <Option
                          value='unknown'
                          selected={industry === 'unknown'}
                        >
                          {t('contact.industry.unknown')}
                        </Option>
                      </StyledSelect>
                    </StyledFormGroup>
                    <StyledFormGroup>
                      <ContactInput
                        required
                        label='Email'
                        name='email'
                        onChange={setEmail}
                        value={email}
                        validator={v => !EMAIL_REGEX.test(v) ? t('contact.invalid-format') : null}
                      />
                    </StyledFormGroup>
                    <StyledFormGroup>
                      <ContactInput
                        label={t('contact.phone')}
                        name='phone'
                        onChange={setPhone}
                        value={phone}
                      />
                    </StyledFormGroup>
                  </InputsContainer>
                  <Columns nb={6}>
                    <ContactInputMessage
                      required
                      inputAs='textarea'
                      label={t('contact.yourMessage')}
                      name='message'
                      onChange={setMessage}
                      value={message}
                      validator={v => v.trim().length === 0 ? t('contact.required') : null}
                      rows='23'
                    />
                  </Columns>
                </StyledRow>
                <StyledCheckbox
                  error={onError && !legal}
                  onChange={setLegal}
                  label={t('contact.legal')}
                />

                <FormGroupCaptcha>
                  <HCaptcha
                    sitekey={process.env.HCAPTCHA_SITEKEY}
                    onVerify={token => handleVerificationSuccess(token)}
                  />
                </FormGroupCaptcha>
                {showError()}
                <ContactButton
                  primary
                  leftIcon={onRequest ? <SpinnerAnimatedIcon /> : <SendIcon />}
                  disabled={onRequest}
                  type='submit'
                >
                  {t('contact.send')}
                </ContactButton>
              </Form>
            )
          }
          </Container>
        </Section>
      </Container>
    </StyledContainer>
  )
}
