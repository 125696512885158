import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import neurOCRLogo from '../../../../../public/images/neurOCR-logo.svg'
import greenMesh from '../../../../../public/images/maillage_vert.png'

import {
  Section,
  P,
  H2,
  H3,
  Container,
} from '../../components'

const LicenseSection = styled(Section)`
  position: relative;
  text-align: center;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.black};
  overflow-x: hidden;

  @media (min-width: 960px) {
    text-align: start;
  }
`

const LicenseContainer = styled(Container)`
  padding: 2.5rem 2.5rem 7.5rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
`

const NeurocrIcon = styled.img`
  filter: invert(1);
  width: 10rem;
  align-self: end;

  @media (max-width: 1024px) {
    width: 9rem;
    margin: auto;
    margin-bottom: 2rem;
  }
`

const Mesh = styled.img`
  position: absolute;
  right: -15%;
  top: -3%;
  transform: scaleX(-1) rotate(40deg);
  height: 30rem;

  @media (max-width: 768px) {
    right: -20%;
    transform: scaleX(-1);
  }
`

const StyledH2 = styled(H2)`
  text-transform: uppercase;
  font-family: Blinker;
  margin: auto;
  margin-left: 5rem;
  margin-bottom: 0;
  letter-spacing: .4rem;
  font-size: 2.1rem;

  @media (max-width: 1024px) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-left: 0;
  }
`

const StyledH3 = styled(H3)`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
`

const StyledP = styled(P)`
  margin-block-end: 1rem;
  margin-block-start: 1rem;
`

const LeftTriangle = styled.div`
  display: inline-block;
  height: 0;
  width: 0;
  border-right : 960px solid transparent;
  border-bottom : 80px solid #FFF;
  position: absolute;
  left: 0;
  bottom: 0;

  @media (max-width: 1024px) {
    border-right: 512px solid transparent;
  }
  @media (max-width: 768px) {
    border-right: 384px solid transparent;
  }
  @media (max-width: 425px) {
    border-right: 212px solid transparent;
  }
  @media (max-width: 320px) {
    border-right: 160px solid transparent;
  }
`

const RightTriangle = styled.div`
  display: inline-block;
  height: 0;
  width: 0;
  border-left: 960px solid transparent;
  border-bottom : 80px solid #FFF;
  position: absolute;
  right: 0;
  bottom: 0;
  @media (max-width: 1024px) {
    border-left: 512px solid transparent;
  }
  @media (max-width: 768px) {
    border-left: 384px solid transparent;
  }
  @media (max-width: 425px) {
    border-left: 212px solid transparent;
  }
  @media (max-width: 320px) {
    border-left: 160px solid transparent;
  }
`

const FlexContainer = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
    text-align: center;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 7rem;
  margin-bottom: 3rem;
  text-align: left;

  div:first-of-type {
    margin-right: 1.5rem;
  }
  div:last-of-type {
    margin-left: 1.5rem;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    margin-top: 1rem;

    div:first-of-type {
      margin-right: 0;
    }
    div:last-of-type {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
`

export default () => {
  const { t } = useTranslation()

  return (
    <LicenseSection>
      <Mesh src={greenMesh} />
      <LeftTriangle />
      <RightTriangle />
      <LicenseContainer>
        <FlexContainer>
          <NeurocrIcon src={neurOCRLogo} />
          <StyledH2>{t('offers.intro.title')}</StyledH2>
        </FlexContainer>
        <Grid>
          {
            t('offers.intro.modes', { returnObjects: true }).map((mode, i) => (
              <div key={`license-intro-modes-${i}`}>
                <StyledH3>{mode.title}</StyledH3>
                <StyledP><b>{mode.subtitle}</b></StyledP>
                <StyledP>
                {
                  mode.description.map((desc, ii) => (
                    <span key={`license-intro-modes-${i}-desc-${ii}`}>{desc}<br /></span>
                  ))
                }
                </StyledP>
              </div>
            ))
          }
        </Grid>
      </LicenseContainer>
    </LicenseSection>
  )
}
