import { hot } from 'react-hot-loader/root'
import React from 'react'
import 'normalize.css'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

import { ThemeProvider, GlobalStyle, theme } from './Theme'

import Home from './pages/Home'
import Innovations from './pages/Innovations'
import Features from './pages/Features'
import Offers from './pages/Offers'
import Terms from './pages/Terms'

import { DemoProvider } from './modules/Demo'
import Contact from './pages/Contact'

import Navigation from './modules/Navigation'
import Footer from './modules/Footer'

const App = () => {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Navigation />
        <div style={{ flex: '1 0 auto' }}>
          <DemoProvider>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/innovations" component={Innovations} />
              <Route exact path="/features" component={Features} />
              <Route exact path="/offers" component={Offers} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/legal-notices" component={Terms} />
              {/* <Route exact path="/demo" component={Demo} /> */}
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </DemoProvider>
        </div>
        <Footer app='neurocr' />
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default hot(App)
