const contact = {
  'title': 'Contact us',
  'subTitle': 'We are at your disposal for all requests',
  'byPhone': 'By phone',
  'byEmail': 'By email',
  'form': 'Via this form',
  'formSuccess': 'Thank you, your request has been sent. See you soon.',
  'goHome': 'Back to home page',
  'lastname': 'Name',
  'firstname': 'First name',
  'company': 'Company',
  'industry': {
    'placeholder': 'Industry',
    'architecture': 'Industries, Consulting firms, Architects',
    'administration': 'Public administrations, National archives, Genealogists',
    'bank': 'Banks, Insurance companies, Law firms, Accountants, Notary offices, Bailiffs',
    'unknown': 'Other',
  },
  'phone': 'Phone number',
  'yourMessage': 'Your message',
  'invalid-format': 'Invalid format',
  'required': 'Required',
  'legal': 'By submitting this form, I agree that the information entered may be used to contact me within the framework of a commercial relationship.',
  'send': 'Submit',
  'errors': {
    'lastname': 'Name required',
    'firstname': 'First name required',
    'email': 'Email required',
    'email-format': 'Invalid email format',
    'message': 'Message required',
    'legal': 'Legal agreement required',
    'captcha': 'Captcha required',
  },
  'error': 'An error has occurred, please try later or contact an administrator via this',
  'error-link': 'form'
}

export default contact
