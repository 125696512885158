const home = {
  'intro': {
    'title': 'The smart OCR that understands as well as reads!',
    'subtitle': 'NeurOCR is the Artificial Intelligence solution for character recognition (OCR: Optical Character Recognition) to manage documentary resources, however complex they may be.',
    'description': [
      'Its purpose: to make these resources accessible (indexing and restitution), usable (rationalization and analysis) and durable (improvement and enrichment).',
      'NeurOCR also allows to meet the needs of textual content retrieval for industrial groups, SME, intermediate-sized enterprise and public institutions.',
    ]
  },
  'content': {
    'title': 'Industries',
    'features': [
      {
        'title': 'Industries, Consulting firms, Architects',
        'description': [
          'Make the processing of each file easier by indexing the different documents in a rational and editable way.',
          'Save physical space by eliminating your shelving once all your archives have been "OCRed".',
        ]
      },
      {
        'title': 'Public administrations, National archives, Genealogists',
        'description': [
          'Convert handwritten documents into an editable format.',
          'Efficiently process poorly scanned and/or damaged documents.',
        ],
      },
      {
        'title': 'Banks, Insurance companies, Law firms, Accountants, Notary offices, Bailiffs',
        'description': [
          'Isolate and compare similar references on all your drawings and technical diagrams.',
          'Digitize up to 300 million documents (and more according to our specific offers).',
        ],
      },
    ],
  },
  'solutions': {
    'title': 'From OCR to ICR',
    'icon-descriptions': [
      {
        'title': 'Data acquisition from all types of documents:',
        'description': 'Normed, standardized, handwritten, typed or complex, NeurOCR\'s AI processes all types of characters in an image (numbers, letters, etc.), even badly scanned or partially deteriorated by time.',
      },
      {
        'title': 'Data acquisition from all types of formats:',
        'description': 'Standard and non-standard formats, PDF, images, plans, NeurOCR\'s AI processes all types of formats and allows the rebuilding of business references.',
      },
      {
        'title': 'Character recognition in all orientations:',
        'description': 'NeurOCR\'s AI is specially trained through a set of rotations, resizing, noise addition and character sharpness variations.',
      },
      {
        'title': 'Assignment of export profiles:',
        'description': 'Through a selection of areas in pages, the solution ensures an automatic correspondence with the fields of the export profiles defined according to the type of document.',
      },
      {
        'title': 'Restitution in all types of formats:',
        'description': 'Once the OCR processing is done, the interface allows the creation of export profiles with automatic assignment by document frame. The export is available in various formats including XLS, DOC, PDF, XML, CSV, JSON and TXT.',
      },
      {
        'title': 'Quality of the OCR processing guaranteed:',
        'description': 'NeurOCR provides unparalleled results thanks to its AI based on deep neural networks and self-learning algorithms that guarantee optimal performance.',
      },
    ],
    'button-features': 'Read more about features',
    'button-offers': 'See our offers',
    'footer': {
      'title': 'Disruptive technology!',
      'subtitle': 'NeurOCR processes video formats in real time!',
      'description': 'The solution can be integrated with embedded systems and enables OCR processing in video formats, in any type of situation !'
    }
  },
}

export default home
