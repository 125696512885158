import React from 'react'

import { Section } from '../components'

import Intro from '../modules/innovations/Intro'
import Content from '../modules/innovations/Content'

export default () => (
  <Section>
    <Intro />
    <Content />
  </Section>
)
