const features = {
  'intro': {
    'title': 'L\'IA au service de vos ressources documentaires',
    'description': [
      'Conçu par Neuronalys, société française experte du domaine de l\'Intelligence Artificielle, NeurOCR s\'adresse principalement aux industries désireuses de tirer le meilleur parti de leurs fonds documentaires.',
      'NeurOCR vous permettra de digitaliser un nombre considérable de documents, des plus simples au plus techniques, tout en réalisant l’extraction des informations essentielles à votre métier de manière ciblée.',
      'Les lourdes opérations de calcul sont assurées par NeuroComputer, un "super-ordinateur" (exclusivement intégré à l\'offre OnPremise). Sa puissance phénoménale assure la rapidité et la stabilité des opérations dans un réseau ouvert ou étanche.',
    ]
  },
  'features': {
    'title': 'Fonctionnalités',
    'videos': [
      {
        'title': 'Le mode Interface',
        'description': '',
      },
      {
        'title': 'Le mode API',
        'description': '',
      },
      {
        'title': 'Datasets et entraînement de l\'IA',
        'description': '',
      },
      {
        'title': 'Cas complexes',
        'description': '',
      },
      {
        'title': 'Les profils d\'export',
        'description': '',
      },
      {
        'title': 'NeurOCR et l\'interopérabilité',
        'description': '',
      },
      {
        'title': 'Détails des offres',
        'description': '',
      },
    ],
  },
  'ai': {
    'title': 'De la pratique naît la maîtrise !',
    'subtitle': 'De manière générique, l\'IA de NeurOCR est entraînée suivant le protocole suivant :',
    'icon-descriptions': [
      {
        'title': '1 - Exhaustivité : création d\'un dataset cohérent et similaire aux données à identifier en condition réelle.',
        'description': [
          'Tout d\'abord, nous fusionnons aléatoirement les caractères issus de nos différents datasets ; ceux-ci sont enrichis constamment tout comme nos modèles, mis à jour en continu.',
          'Le dataset issu de cette fusion peut être enrichi avec une partie des documents originaux pour améliorer la qualité de la reconnaissance selon l\'état des documents et les attentes. Normés, standardisés, manuscrits, dactylographiés ou complexes, l\'IA traite tous types d\'informations dans une image (chiffres, lettres, etc.), même mal scannée ou partiellement détériorée par le temps.',
        ]
      },
      {
        'title': '2 - Adaptabilité : traitement d\'image pour les situations difficiles.',
        'description': [
          'Afin de maintenir la qualité d\'océrisation, le dataset subi un jeu de rotations, redimensionnements, ajout de bruit et variations de netteté des caractères.',
          'NeurOCR pourra ainsi faire face aux plans les plus complexes et aux documents mal scannés ou partiellement.',
        ]
      },
      {
        'title': '3 - Efficacité : réalisation de différents réseaux de neurones artificiels.',
        'description': [
          'Ces réseaux convolutifs performants seront connectés aux données du dataset et optimisés. Une fois l\'entraînement validé, le traitement en conditions réelles peut commencer.',
          'Dans les cas les plus complexes, des entraînements spécifiques sont réalisés en incluant la donnée cible du projet.',
        ]
      },
    ],
    'info': 'Nous sommes à l\'écoute de vos questions et de vos demandes d\'informations',
    'button': 'Contactez-nous',
  }
}

export default features
